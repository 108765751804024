import { createTheme } from '@mui/material/styles';

const muibuttontheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: 'white',
          color: 'rgb(1, 99, 172)',
          '&:hover': {
            background: 'white', // Ensure the background color stays white on hover
          },
        },
      },
    },
  },
});

export default muibuttontheme;
