import React from 'react';
import axios from 'axios';
// import NavBar from './NavBar';
import HeaderTemp from '../HeaderTemp';
import Footer from '../Footer';
import NavBarTest from '../NavBarTest';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";

const InnovationHub = () => {
  return (
    <div>
      <HeaderTemp />
      <NavBarTest />
      <div className="container text-center" style={{ padding: "50px 0" }}>
        <h1>Page Under Construction</h1>
        <p>We are working hard to bring you this page. Please check back later.</p>
        <img src='https://res.cloudinary.com/dhzvwxfod/image/upload/v1717864848/page_under_construction_thniaa.jpg' alt="Under Construction" style={{ maxWidth: "100%", height: "auto", marginTop: "20px" }} />
      </div>
      <Footer />
    </div>
  );
};

export default InnovationHub;
