import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import '../TeamMembers.css';

const ourDirectors = [
    {
        name: 'Sam Coomar',
        title: ' Strategic Advisor - USA',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717086257/Sam_n2jkbe.jpg',
        linkedin: 'https://www.linkedin.com/in/samcoomar/'
    },
    {
        name: 'Sujan Selvan',
        title: 'Australia',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717857391/sujanselven_h3zjtp.jpg',
        linkedin: 'https://www.linkedin.com/in/sujanselven/'
    },
    {
        name: 'Kishoth Navaretnarajah',
        title: 'Sri Lanka',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717857393/Kishoth_zgagro.jpg',
        linkedin: 'https://www.linkedin.com/in/nkishoth/'
    },
    {
      name: 'Marc Bertoli',
      title: 'Switzerland',
      image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717857061/marc_qflnvm.jpg',
      linkedin: 'https://www.linkedin.com/in/marc-bertoli-12603218/'
  },


];

const Mentors = () => {
    return (
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <center><h5 className="mb-3">Behind the Success: Our Dedicated Advisors</h5></center>
          </div>
          <div className="row g-4">
            {ourDirectors.map((director, index) => (
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                <div className="team-item position-relative rounded overflow-hidden">
                  <div className="overflow-hidden">
                    <img className="img-fluid" src={director.image} alt={director.name} style={{ minHeight: 300 }} />
                  </div>
                  <div className="team-text bg-light text-center p-4">
                    <h5>{director.name}</h5>
                    <p className="text-primary">{director.title}</p>
                    <div className="team-social text-center">
                        <a className="btn btn-square" href={director.linkedin}><i className="fab fa-linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default Mentors;
