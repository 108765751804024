import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./VolunteerRegistrationForm.css";
import "./ManageUsers.css";
import SideBarTest from "./SideBarTest";

const ManageGallery = () => {
  const [sdgOptions, setSdgOptions] = useState([]);
  const [selectedSdg, setSelectedSdg] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const fetchSdgOptions = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/api/sdg');
        setSdgOptions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error retrieving sdg options:', error);
      }
    };

    fetchSdgOptions();
  }, []);

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append('sdg_id', JSON.stringify(selectedSdg));

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/api/uploadGalleryImages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Upload successful:', response.data);
      alert('Images uploaded successfully');
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Error uploading images');
    }
  };

  return (
    <div>
      <style>
        {`
          .main-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #f1f1f1;
          }

          .manage-gallery-container {
            margin: 20px;
            padding: 20px;
            border-radius: 10px;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .manage-gallery-form {
            display: flex;
            flex-direction: column;
          }

          .form-group {
            margin-bottom: 15px;
          }

          label {
            font-weight: bold;
            margin-bottom: 5px;
          }

          input[type="text"], input[type="file"], textarea, select {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
          }

          textarea {
            resize: vertical;
          }

          .btn-submit {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
          }

          .btn-submit:hover {
            background-color: #0056b3;
          }

          /* Specific Styles for ManageGallery */
          .manage-gallery-form label {
            font-size: 18px;
          }

          .manage-gallery-form input[type="file"] {
            padding: 5px;
          }
        `}
      </style>
      <SideBarTest />
      <div className="main-container">
        <div className="manage-gallery-container">
          <h1>Manage Gallery</h1>
          <form className="manage-gallery-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Select SDG:</label>
              <select value={selectedSdg} onChange={(e) => setSelectedSdg(e.target.value)}>
                <option value="">--Select SDG--</option>
                {sdgOptions.map((sdg) => (
                  <option key={sdg.sdg_id} value={sdg.sdg_id}>
                    {sdg.sdg}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Upload Images:</label>
              <input type="file" onChange={saveFile} />
            </div>
            <button type="submit" className="btn-submit">Upload</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageGallery;
