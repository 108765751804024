import React from 'react';
import  {useState } from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import { useNavigate } from 'react-router-dom';
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBBtn
  } from 'mdb-react-ui-kit';
  
  import { PDFDownloadLink, PDFViewer, Document, Page, Text, View,Table, TableCell, TableHeader ,Image, StyleSheet } from '@react-pdf/renderer';

const MakeImpact = () => {

    const [showCardMessage, setShowCardMessage] = useState(false);
    const [pdfContent, setPdfContent] = useState(null);
    const navigate = useNavigate();

    const handleClick = () => {
        setShowCardMessage(true);
        // 1
      };

      const handleVolunteerClick = () => {
        navigate('/VolunteerTest');
      };

    

    const handleCloseCardMessage = () => {
        setShowCardMessage(false);
      };
  return (
    <div>
        <section class="cta-section section-padding section-bg">
            <div class="container">
                <div class="row justify-content-center align-items-center">

                    <div class="col-lg-5 col-12 ms-auto">
                        <h2 class="mb-0">Make an impact. <br/> Save lives.</h2>
                    </div>

                    <div class="col-lg-5 col-12">
                        <a onClick={handleClick} class="me-4">Make a donation</a>

                        <a onClick={handleVolunteerClick} class="custom-btn btn smoothscroll">Become a volunteer</a>
                    </div>

                </div>
            </div>
        </section>
        {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>We are not accepting donations at this time.</MDBCardText>
              <div className="button-container">
                  {pdfContent && (
                    <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Generating PDF...' : 'Download Receipt'
                      }
                    </PDFDownloadLink>
                  )} 
                </div>
            </MDBCardBody>
            <MDBCardFooter>  
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button">
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}

    </div>
  );
};

export default MakeImpact;
