import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
 import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

import './index.css';
import App from './App';
//import AdminApp from './AdminApp';
import reportWebVitals from './reportWebVitals';

config.autoAddCss = false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
