import React, { useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import "./NavBarTest.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './css/bootstrap.min.css';
import './css/bootstrap-icons.css';
import './css/templatemo-kind-heart-charity.css';
import logo from "./Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import muibuttontheme from "./muibuttontheme";

function NavBarTest() {
  const [click, setClick] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [volunteerId, setVolunteerId] = useState('');
  const navigate = useNavigate();

  const handleClick = () => setClick(!click);

  const handleAboutClick = () => {
    navigate('/about');
  };

  const handleContactClick = () => {
    navigate('/ContactPage');
  };

  const handleProjectsClick = () => {
    navigate('/projects');
  };

  const handleEventsClick = () => {
    navigate('/events');
  };

  const handleVolunteerClick = () => {
    navigate('/VolunteerTest');
  };

  const handleAppointmentClick = () => {
    navigate('/VisitingAppointment');
  };

  const linkStyle = {
    backgroundColor: "white",
    color: "rgb(1, 99, 172)",
    padding: "5px 10px",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background-color 0.3s, color 0.3s",
  };

  const linkHoverStyle = {
    backgroundColor: "rgb(240, 240, 240)", // Light gray on hover
  };

  useEffect(() => {
    // Check if user is already logged in
    const token = sessionStorage.getItem('token');
    const userRole = sessionStorage.getItem('userRole');
    const userId = sessionStorage.getItem('userID');
    setUserId(userId);
    setUserRole(userRole);
  
    if (token && userRole && userId) {
      setLoggedIn(true);
      // Fetch user details based on userID and set userDetails state
      // fetchUserDetails(userId);
    }
  }, []);


  const handleLogout = () => {
    clearSession();
    setLoggedIn(false);
  };

  const clearSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('userID');
    sessionStorage.removeItem('userName');
  };

  // const handleDonatePageNav = () => {
  //   if (userRole === 'donor') {
  //     navigate('/DonatePageforDonor');
  //   } else {
  //     navigate('/DonateOnUSD');
  //   }
  // };

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">

          <NavLink exact to="/" className="nav-logo">
            <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699546192/Logo_fofd1y.png" alt="Logo" className="logo-image" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>

            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/projects"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Projects
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Events"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Events
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Gallery"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Gallery
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/InnovationHub" 
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                iHub
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/ContactPage"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>

            {loggedIn ? (
              <>

              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-links"
                  onClick={handleClick}
                >
                  <i className="fas fa-user"></i>
                </a>

                <div className="dropdown-content">

                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    className="dropdown-link"
                    onClick={handleLogout}
                  >
                    Logout
                  </NavLink>

                </div>
              </li>
              </>

            ) : (
              <>

              </>
            )}
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBarTest;