import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import React, { useState, useEffect } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SignUpTemp from './SignUpTemp';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.eegaisrilanka.org/">
        eegai.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Register() {
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//   };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [notification, setNotification] = useState('');
  const [error, setError] = useState(''); 
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [click, setClick] = useState(false);

  const [imageIndex, setImageIndex] = useState(0);

  const imageUrls = [
    'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717156065/1_qn3gbt.png',
    'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717156064/2_kej1jd.png',
    'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717156070/3_qrb8r7.png',
    'https://res.cloudinary.com/dhzvwxfod/image/upload/v1700149770/EEGAI_-_Login_e24alw.jpg',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username,
      password,
    };

    try {
      const response = await axios.post('https://eegaibackend.onrender.com/submit-loginform', data);

      const { token, userRole, userId, userName, userDetails } = response.data;

      if (rememberMe) {
        // If "Remember Me" is checked, store username and password in cookies
        document.cookie = `username=${username}; max-age=2592000`; // 30 days expiration
        document.cookie = `password=${password}; max-age=2592000`; // 30 days expiration
      } else {
        // If "Remember Me" is not checked, clear the cookies
        document.cookie = 'username=; max-age=0';
        document.cookie = 'password=; max-age=0';
      }


      setSession(token, userId, userName, userRole);
      setLoggedIn(true);
      setUserDetails(userDetails);

      setUsername('');
      setPassword('');

      // alert(userId);
      setNotification('Login Successfully!');
      setError(''); 

      if (userRole === 'admin') {
        navigate('/admin');
      } else if (userRole === 'volunteer') {
        navigate('/VolunteerDashboard');
      } else if (userRole === 'staff') {
        navigate('/StaffDashboard');
      } else if (userRole === 'donor') {
        navigate('/DonorDashboard');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 401) {
          setError('Invalid username or password');
        } else {
          setError('Internal Server Error');
        }
      } else {
        setError('Error connecting to the server');
      }
    }
  };

  useEffect(() => {
    // Check if there are stored username and password cookies
    const storedUsername = document.cookie.replace(/(?:(?:^|.*;\s*)username\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const storedPassword = document.cookie.replace(/(?:(?:^|.*;\s*)password\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (storedUsername && storedPassword) {
      // If cookies exist, pre-fill the username and password fields
      setUsername(storedUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleClick = () => {
    navigate('/Signup');
  };

  const setSession = (token, userId, userName, userRole) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userID', userId);
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userRole', userRole); 
  };
  
  useEffect(() => {
    // Check if user is already logged in
    const token = sessionStorage.getItem('token');
    const userRole = sessionStorage.getItem('userRole');
    const userId = sessionStorage.getItem('userID');
  
    if (token && userRole && userId) {
      setLoggedIn(true);
      // Fetch user details based on userID and set userDetails state
      fetchUserDetails(userId);
    }
  }, []);
  

  const handleLogout = () => {
    clearSession();
    setLoggedIn(false);
    setUserDetails(null);
    navigate('/');
  };

  const clearSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('userID');
  };

  const fetchUserDetails = async (userID) => {
    try {
      const response = await axios.get(`https://eegaibackend.onrender.com/user/${userID}`);
      const userDetails = response.data;
      setUserDetails(userDetails);
    } catch (error) {
      console.error(error);
    }
  };


  const handleBtnClick = () => setClick(!click);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${imageUrls[imageIndex]})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1,bgcolor: 'rgb(1, 99, 172)' }}>
              <LockOutlinedIcon />
            </Avatar>
            <br></br>
            {/* <b><Typography component="h1" variant="h5">
                Join EEGAI today and start your journey with us
            </Typography></b> */}
            {/* <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" > */}
            <center><h3>Join EEGAI Today and Start Your Journey With Us</h3></center>
            {/* </div> */}
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                
            <div class="col-lg-6 col-12 mx-auto">
                            <form class="custom-form donate-form" action="#" method="get" role="form">
                                <center>

                                <div class="row">

                                        <Button 
                                            type="button" 
                                            variant="contained" 
                                            color="primary" 
                                            class="form-control mt-4" 
                                            onClick={() => navigate('/VolunteerTest')}
                                            style={{ width: '800px' }}
                                            >
                                            Register As Volunteer
                                        </Button>

                                        <Button 
                                        type="button" 
                                        variant="contained" 
                                        color="primary" 
                                        class="form-control mt-4" 
                                        onClick={() => navigate('/DonorSignup')}
                                        style={{ width: '800px' }}
                                        >
                                        Register As Donor
                                        </Button>

                                        {/* <Button 
                                        type="button" 
                                        variant="contained" 
                                        color="primary" 
                                        class="form-control mt-4" 
                                        onClick={() => navigate('/StaffRegistrationForm')}
                                        style={{ width: '800px' }}
                                        >
                                        Register As Staff
                                        </Button> */}

                                        <Button 
                                        type="button" 
                                        variant="contained" 
                                        color="primary" 
                                        class="form-control mt-4" 
                                        onClick={() => navigate('/VisitingAppointment')}
                                        style={{ width: '800px' }}
                                        >
                                        Get Appointment
                                        </Button>

                                </div>
                                </center>
                            </form>
                        </div>
 
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}