import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Select from 'react-select';
// import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-number-input';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiTelInput } from 'mui-tel-input';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';

import Snackbar from '@mui/material/Snackbar';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


const options = [
  { value: 'skill1', label: 'Skill 1' },
  { value: 'skill2', label: 'Skill 2' },
  { value: 'skill3', label: 'Skill 3' },
];

const interestOptions = [
  { value: 'interest1', label: 'Interest 1' },
  { value: 'interest2', label: 'Interest 2' },
  { value: 'interest3', label: 'Interest 3' },
];

const defaultTheme = createTheme();

const backgroundImageStyle = {
  backgroundImage: 'url("path/to/your/image.jpg")', // Replace with the actual path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh', // Adjust the height as needed
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export default function VolunteerSignup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dob, setDob] = useState(null);  
    const [contactNo, setContactNo] = useState('');
    const [location, setLocation] = useState('');
    const [skills, setSkills] = useState([]);
    const [interests, setInterests] = useState([]);
    const [cvFile, setCVFile] = useState(null);
    const [cvFilename, setCVFilename] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [errors, setErrors] = useState({});
    const [isUsernameTaken, setIsUsernameTaken] = useState(false);
    const [showCardMessage, setShowCardMessage] = useState(false);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
  
    const saveFile = (e) => {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    };
  
    const checkUsernameAvailability = () => {
      if (username.trim() === '') {
        return;
      }
  
      axios
        .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
        .then((response) => {
          setIsUsernameTaken(response.data.isTaken);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    const validateForm = () => {
      const errors = {};
  
      if (username.trim() === '') {
        errors.username = 'Username is required';
      } else if (username.length < 3) {
        errors.username = 'Username should be at least 3 characters';
      } else if (!/^[A-Za-z][A-Za-z0-9_]{7,29}$/.test(username)) {
        errors.username = 'Invalid username format. Username should start with an alphabet and can contain alphabets, numbers, or an underscore. Length should be 8-30 characters.';
      }
  
      if (!isValidEmail(email)) {
        errors.email = 'Invalid email format';
      }
  
      if (password.length < 6) {
        errors.password = 'Password should be at least 6 characters';
      }
  
      // Add additional password validation if needed
      if (!/\d/.test(password)) {
        errors.password = 'Password should contain at least one digit';
      }
  
      if (!/[a-zA-Z]/.test(password)) {
        errors.password = 'Password should contain at least one letter';
      }
  
      if (!/[!@#$%^&*]/.test(password)) {
        errors.password = 'Password should contain at least one special character (!@#$%^&*)';
      }
  
      if (!isValidContactNo(contactNo)) {
        errors.contactNo = 'Invalid contact number format';
      }
  
      setErrors(errors);
  
      return Object.keys(errors).length === 0;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Create a FormData object
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      formData.append('cvFile', cvFile);
      formData.append("cvFilename", cvFilename);
  
      if (validateForm()) {
        // Form validation successful, proceed with submission
        formData.append("username", username);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("dob", dob);
        formData.append("location", location);
        formData.append("contactNo", contactNo);  
  
        formData.append("skills", JSON.stringify(skills));
        formData.append("interests", JSON.stringify(interests));
        console.log(formData);
        // Send a POST request to the server
        axios
          .post('https://eegaibackend.onrender.com/api/checkEmail', { email }) // Check if email is already registered
          .then((response) => {
            if (response.data.isRegistered) {
              setErrors({ email: 'This email is already registered' });
            } else {
              // Check if the email is already registered
          axios
          .post('https://eegaibackend.onrender.com/api/checkEmail', { email })
          .then((response) => {
            if (response.data.isRegistered) {
              setErrors({ email: 'This email is already registered' });
            } else {
              // Email is not registered, proceed with username availability check
              // alert("Email Verified");
              axios
                .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
                .then((response) => {
                  if (response.data.isTaken) {
                    setErrors({ username: 'This username is already taken' });
                  } else {
                    // alert("Username Verified");
                    // Username is available, proceed with form submission
                    // Send a POST request to the server
                    axios
                    .post('https://eegaibackend.onrender.com/api/submitForm', formData) // Submit the form data
                    .then((response) => {
                      // Handle the response from the server
                      console.log(response.data);
                      // alert("Response received");
                      // Reset the form fields if needed
                      setUsername('');
                      setEmail('');
                      setPassword('');
                      setDob(null);
                      setLocation('');
                      setContactNo('');
                      setSkills([]);
                      setInterests([]);
                      setErrors({});
                      setShowCardMessage(true);
                      setOpen(true);
                      setCVFilename('');
                      setCVFile('');
                      setFileName('');
                      setFile('');
                      // navigate('/');
                    })
      
                      .catch((error) => {
                        // Handle any errors that occur during the request
                        console.error(error);
                      });
                  }
                })
                .catch((error) => {
                  // Handle error checking username
                  console.error(error);
                });
            }
          })
                .catch((error) => {
                  // Handle any errors that occur during the request
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            // Handle error checking email
            console.error(error);
          });
      }
    };
    
    // Validation helper functions
    const isValidEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };
    
    const isValidContactNo = (contactNo) => {
      return isValidPhoneNumber(contactNo);
    };
  
    // const handleSkillsChange = (selectedOptions) => {
    //   const selectedSkills = selectedOptions.map((option) => option.value);
    //   setSkills(selectedSkills);
    // };

    const handleSkillsChange = (event) => {
        const selectedSkills = event.target.value;
        setSkills(selectedSkills);
      };
      
  
    // const handleInterestsChange = (selectedOptions) => {
    //   const selectedInterests = selectedOptions.map((option) => option.value);
    //   setInterests(selectedInterests);
    // };

    const handleInterestsChange = (event) => {
        const selectedInterests = event.target.value;
        setInterests(selectedInterests);
      };
      
  
    const handleContactNoChange = (value) => {
      setContactNo(value);
    };
    
    const handleClick = () => {
      window.location.href = '/Login';
    }; 
  
    const handleCVFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setCVFile(file);
        setCVFilename(file.name);
      } else {
        // File not selected, handle the error or show an error message
        console.error('No file selected');
      }
    };
  
    const handleCloseCardMessage = () => {
      setShowCardMessage(false);
      navigate('/'); 
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    

  return (

    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'rgb(1, 99, 172)' }}>
            <VolunteerActivismIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Volunteer Registration
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={checkUsernameAvailability}
              required
            />
            {isUsernameTaken && <Alert variant="outlined" severity="error">
            This username is already taken
              </Alert>}
            {errors.username && <Alert variant="outlined" severity="error">
            {errors.username}
              </Alert>}
            {/* {errors.username && <p className="error">{errors.username}</p>} */}

            {/* {isUsernameTaken && <p className="error">This username is already taken</p>} */}

            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* {errors.email && <p className="error">{errors.email}</p>} */}
            {errors.email && <Alert variant="outlined" severity="error">
            {errors.email}
              </Alert>}

            <TextField
              label="Password"
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {errors.password && <p className="error">{errors.password}</p>}

            {/* <DatePicker
              id="dob"
              selected={dob}
              onChange={(date) => setDob(date)}
              dateFormat="dd/MM/yyyy"
            /> */}

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: '80%' }}>
                <DatePicker />
              </div>
            </LocalizationProvider> */}


            <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      'DatePicker',
                      'MobileDatePicker',
                      'DesktopDatePicker',
                      'StaticDatePicker',
                    ]}
                  >
                    <DemoItem >
                      <DatePicker label="DOB" slotProps={{
                        textField: {
                          helperText: 'MM/DD/YYYY',
                        },
                      }} />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>

            {/* <PhoneInput
              id="contactNo"
              defaultCountry="LK"
              value={contactNo}
              onChange={handleContactNoChange}
              required
            />
            {errors.contactNo && <p className="error">{errors.contactNo}</p>} */}

            <br></br>

            <MuiTelInput defaultCountry="LK"label="Phone Number" value={contactNo} onChange={handleContactNoChange} style={{ width: '395px' }}  />

            <br></br><br></br>


            <Button onChange={saveFile} component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                Upload Profile Pic
            <VisuallyHiddenInput type="file" />
            </Button>

            <span style={{ marginRight: '20px' }}></span>

            <Button style={{ width: '160px' }} component="label" variant="outlined" accept=".pdf,.doc,.docx" onChange={handleCVFileChange} startIcon={<CloudUploadIcon />}>
                Upload CV
            <VisuallyHiddenInput type="file" />
            </Button>

            <TextField
              label="Home Town"
              variant="outlined"
              margin="normal"
              fullWidth
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />

            {/* <Select
              id="skills"
              options={options}
              value={options.filter((option) => skills.includes(option.value))}
              onChange={handleSkillsChange}
              isMulti
              placeholder="Select skills"
            /> */}

            <InputLabel id="skills-label">Skills</InputLabel>
            <Select
            labelId="skills-label"
            id="skills"
            multiple
            value={skills}
            onChange={handleSkillsChange}
            variant="outlined"
            fullWidth
            >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
            </Select>

            


            {/* <Select
              id="interests"
              options={interestOptions}
              value={interestOptions.filter((option) => interests.includes(option.value))}
              onChange={handleInterestsChange}
              isMulti
              placeholder="Select interests"
            /> */}

            <InputLabel id="interests-label">Interests</InputLabel>
            <Select
            labelId="interests-label"
            id="interests"
            multiple
            value={interests}
            onChange={handleInterestsChange}
            variant="outlined"
            fullWidth
            >
            {interestOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
            </Select>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Thank you for the volunteering!
            </Alert>
            </Snackbar>

            {showCardMessage && (
            <center>
              <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
                <MDBCardBody>
                  <MDBCardTitle>Thank you for the volunteering!</MDBCardTitle>
                  <MDBCardText>With supporting text below as a natural lead-in to additional content.</MDBCardText>
                  {/* <div className="button-container">
                      {pdfContent && (
                        <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                          {({ blob, url, loading, error }) =>
                            loading ? 'Generating PDF...' : 'Download Receipt'
                          }
                        </PDFDownloadLink>
                      )} 
                    </div> */}
                </MDBCardBody>
                <MDBCardFooter>  <MDBBtn onClick={handleCloseCardMessage}>Close</MDBBtn>
                </MDBCardFooter>
              </MDBCard>
            </center>
          )}


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            {/* <br></br>
              {error && <Alert variant="outlined" severity="error">
              {error}
              </Alert>}
              <br></br> */}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/SignIn" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
