import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import React, { useState, useEffect } from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { MuiTelInput } from 'mui-tel-input';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const sdgOptions = [
    { value: 'No Poverty', label: 'No Poverty' },
    { value: 'Zero Hunger', label: 'Zero Hunger' },
    { value: 'Good Health and Well-Being', label: 'Good Health and Well-Being' },
    { value: 'Quality Education', label: 'Quality Education' },
    { value: 'Gender Equality', label: 'Gender Equality' },
    { value: 'Clean Water and Sanitation', label: 'Clean Water and Sanitation' },
    { value: 'Affordable and Clean Energy', label: 'Affordable and Clean Energy' },
    { value: 'Decent Work and Economic Growth', label: 'Decent Work and Economic Growth' },
    { value: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE', label: 'INDUSTRY, INNOVATION AND INFRASTRUCTURE' },
    { value: 'Reduced Inequalities', label: 'Reduced Inequalities' },
    { value: 'Sustainable Cities and Communities', label: 'Sustainable Cities and Communities' },
    { value: 'Responsible Consumption and Production', label: 'Responsible Consumption and Production' },
    { value: 'Climate Action', label: 'Climate Action' },
    { value: 'Life Below Water', label: 'Life Below Water' },
    { value: 'Life On Land', label: 'Life On Land' },
    { value: 'Peace Justice and Strong Instituations', label: 'Peace Justice and Strong Instituations' },
    { value: 'Partnership for the Goals', label: 'Partnership for the Goals' },
    // Add more options for interests as needed
  ];
  
  const organizationOptions = [
    { value: 'Nonprofit Organization', label: 'Nonprofit Organization' },
    { value: 'Business Organization', label: 'Business Organization' },
    { value: 'Corporation', label: 'Corporation' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
    { value: 'Cooperative', label: 'Cooperative' },
    { value: 'Government Organization', label: 'Government Organization' },
    { value: 'Social Enterprise', label: 'Social Enterprise' },
    { value: 'Professional Associations', label: 'Professional Associations' },
  
    // Add more options for interests as needed
  ];
  

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.eegaisrilanka.org/">
        eegai.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function DonorSignup() {
  const [fullname, setFullName] = useState('');
  const [nicNo, setNicNo] = useState('');
  const [address, setAddress] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [donationPreferance, setDonationPreferance] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationname, setOrganizationName] = useState('');
  const [registrationno, setRegistrationNo] = useState('');
  const [typeoforganization, setTypeofOrganization] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [role, setUserType] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showCardMessage, setShowCardMessage] = useState(false);
  const navigate = useNavigate();


  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const checkUsernameAvailability = () => {
    if (username.trim() === '') {
      return;
    }

    axios
      .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
      .then((response) => {
        setIsUsernameTaken(response.data.isTaken);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkEmailAvailability = () => {
    if (email.trim() === '') {
      return;
    }

    axios
      .post('https://eegaibackend.onrender.com/api/checkEmail', { email })
      .then((response) => {
        setIsEmailTaken(response.data.isTaken);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    if (validateForm()) {

      formData.append("fullname", fullname);
      formData.append("nicNo", nicNo);
      formData.append("address", address);
      formData.append("contactNo", contactNo);
      formData.append("role", role);
      // formData.append("donationPreferance", donationPreferance);
      formData.append("donationPreferance", JSON.stringify(donationPreferance));
      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("organizationname", organizationname);
      formData.append("registrationno", registrationno);
      formData.append("typeoforganization", typeoforganization);

     // Check if the email is already registered
    axios
    .post('https://eegaibackend.onrender.com/api/checkEmail', { email })
    .then((response) => {
      if (response.data.isRegistered) {
        setErrors({ email: 'This email is already registered' });
      } else {
        // Email is not registered, proceed with username availability check
        axios
          .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
          .then((response) => {
            if (response.data.isTaken) {
              setErrors({ username: 'This username is already taken' });
            } else {
              // Username is available, proceed with form submission
              axios
              .post('https://eegaibackend.onrender.com/submit-donorform', formData)
              .then((response) => {
                // Handle the response from the server
                console.log(response.data);
                // Reset the form fields if needed
                setFullName('');
                setNicNo('');
                setAddress('');
                setContactNo('');
                setDonationPreferance([]);
                setUsername('');
                setEmail('');
                setPassword('');
                setOrganizationName('');
                setTypeofOrganization('');
                setRegistrationNo('');
                setFile('');
                setFileName('');
                setOpen(true);
                setErrors({});
                setShowCardMessage(true);
                // alert("Registration Successful");
              })
                .catch((error) => {
                  // Handle any errors that occur during the request
                  console.error(error);
                });
            }
          })
          .catch((error) => {
            // Handle error checking username
            console.error(error);
          });
      }
    })
    .catch((error) => {
      // Handle error checking email
      console.error(error);
    });
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (fullname.trim() === '') {
        errors.fullname = 'Fullname is required';
    } else if (!isValidFullName(fullname)) {
      errors.fullname = 'Invalid Full Name format';
    }

    if (nicNo.trim() === '') {
        errors.nicNo = 'NIC No. is required';
    } else if (!isValidNIC(nicNo)) {
      errors.nicNo = 'Invalid NIC Number format';
    }

    if (address.trim() === '') {
        errors.address = 'Address is required';
      }

      // Username validation
    if (username.trim() === '') {
      errors.username = 'Username is required';
    } else if (username.length < 3) {
      errors.username = 'Username should be at least 3 characters';
    } else if (!/^[A-Za-z][A-Za-z0-9_]{7,29}$/.test(username)) {
      errors.username = 'Invalid username format. Username should start with an alphabet and can contain alphabets, numbers, or an underscore. Length should be 8-30 characters.';
    }

    if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }

    if (password.length < 6) {
      errors.password = 'Password should be at least 6 characters';
    }

      // Add additional password validation if needed
    if (!/\d/.test(password)) {
      errors.password = 'Password should contain at least one digit';
    }

    if (!/[a-zA-Z]/.test(password)) {
      errors.password = 'Password should contain at least one letter';
    }

    if (!/[!@#$%^&*]/.test(password)) {
      errors.password = 'Password should contain at least one special character (!@#$%^&*)';
    }

    if (contactNo.trim() === '') {
        errors.contactNo = 'Contact Number is required';
      } else if (!isValidContactNo(contactNo)) {
      errors.contactNo = 'Invalid contact number format';
    }

    setErrors(errors);
    return isValid;
  };

//   const handleSDGChange = (selectedOptions) => {
//     const selectedSDG = selectedOptions.map((option) => option.value);
//     setDonationPreferance(selectedSDG);
//   };


  const handleOrganizationChange = (selectedOption) => {
    setTypeofOrganization(selectedOption.value);
  };

  const handleSDGChange = (event) => {
    const selectedSDG = event.target.value;
    setDonationPreferance(selectedSDG);
  };

  // Validation helper functions
  const isValidFullName = (fullname) => {
    const fullNameRegex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    return fullNameRegex.test(fullname);
  };

  // Validation helper functions
  const isValidNIC = (nicNo) => {
    const nicNoRegex = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/m;
    return nicNoRegex.test(nicNo);
  };

  // Validation helper functions
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
      
  const isValidContactNo = (contactNo) => {
    //const contactNoRegex = /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$`/;
    //return contactNoRegex.test(contactNo);
    return isValidPhoneNumber(contactNo);
  };

    
  const handleContactNoChange = (value) => {
    setContactNo(value);
  };

  const handleClick = () => {
    window.location.href = '/Login';
  }; 

  const handleCloseCardMessage = () => {
    setShowCardMessage(false);
    navigate('/'); 
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://res.cloudinary.com/dhzvwxfod/image/upload/v1717156064/2_kej1jd.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'rgb(1, 99, 172)' }}>
            <VolunteerActivismIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Donor Registration
            </Typography>
            <Box component="form" noValidate onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <TextField
              label="Fullname"
              variant="outlined"
              margin="normal"
              fullWidth
              id="fullname"
              value={fullname}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            {errors.fullname && <Alert variant="outlined" severity="error">
            {errors.fullname}
              </Alert>}
            </Grid>

            <Grid item xs={12} sm={6}>
            <TextField
              label="Nic No."
              variant="outlined"
              margin="normal"
              fullWidth
              id="nicno"
              value={nicNo}
              onChange={(e) => setNicNo(e.target.value)}
              required
            />
            {errors.nicNo && <Alert variant="outlined" severity="error">
            {errors.nicNo}
              </Alert>}
              </Grid>

            <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              variant="outlined"
              margin="normal"
              fullWidth
              id="nicno"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            {errors.address && <Alert variant="outlined" severity="error">
            {errors.address}
              </Alert>}
              </Grid>
            
              <Grid item xs={12} sm={6}>
            <TextField
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={checkUsernameAvailability}
              required
            />
            {isUsernameTaken && <Alert variant="outlined" severity="error">
            This username is already taken
              </Alert>}
            {errors.username && <Alert variant="outlined" severity="error">
            {errors.username}
              </Alert>}
              </Grid>
            {/* {errors.username && <p className="error">{errors.username}</p>} */}

            {/* {isUsernameTaken && <p className="error">This username is already taken</p>} */}

            <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* {errors.email && <p className="error">{errors.email}</p>} */}
            {errors.email && <Alert variant="outlined" severity="error">
            {errors.email}
              </Alert>}
              </Grid>

              <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* {errors.password && <p className="error">{errors.password}</p>} */}
            {errors.password && <Alert variant="outlined" severity="error">{errors.password}</Alert>}
            </Grid>   

            <Grid item xs={12} >

            <MuiTelInput defaultCountry="LK"label="Contact Number" value={contactNo} onChange={handleContactNoChange} fullWidth />
            <br></br>
            {errors.contactNo && <Alert variant="outlined" severity="error">{errors.contactNo}</Alert>}
            </Grid>
            <br></br>

            <Grid item xs={12}>
            <InputLabel id="donationPreferance">Donation Preferences</InputLabel>
            <Select
            labelId="donationPreferance"
            id="donationPreferance"
            multiple
            value={donationPreferance}
            onChange={handleSDGChange}
            variant="outlined"
            fullWidth
            required
            >
            {sdgOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
            </Select>
            {errors.interests && <Alert variant="outlined" severity="error">{errors.interests}</Alert>}
            </Grid>


            <Grid item xs={12}>

            <Button onChange={saveFile} component="label" variant="outlined" startIcon={<CloudUploadIcon sx={{ mr: 1 }}/>}>
                Upload Profile Pic
            <VisuallyHiddenInput type="file" />
            </Button>

            <span style={{ marginLeft: '10px' }}>{fileName && `File: ${fileName}`}</span>
            {errors.profilePic && <Alert variant="outlined" severity="error">{errors.profilePic}</Alert>}

            </Grid>


            </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>

              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Thank you for becoming a registered Donor!
                </Alert>
            </Snackbar>

              {/* <Stack sx={{ width: '100%' }} spacing={2}>
              {error && <Alert severity="error">{error}</Alert>}
              </Stack> */}
              {/* <br></br>
              {error && <Alert variant="outlined" severity="error">
              {error}
              </Alert>}
              <br></br> */}
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
                <Grid item>
                  <Link href="/SignIn" variant="body2">
                    {"Already have an account? Sign in"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}