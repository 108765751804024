import React from 'react';
import HeaderTemp from './HeaderTemp';
import NavBarTest from './NavBarTest';
import Footer from './Footer';
import DonateTemp from './DonateTemp';

const Crowdfunding = () => {
  return (
    <div>
      <HeaderTemp />
      <NavBarTest />
      <DonateTemp />
      <Footer />
  </div>
  );
};

export default Crowdfunding;
