import React from "react";
import DonorSideBar from "./DonorSideBar";

function DonorDashboard() {
  return (
    <div>
      <DonorSideBar />
    </div>
  );
}
export default DonorDashboard;