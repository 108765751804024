import React from 'react';
import HeaderTemp from './HeaderTemp';
import NavBarTest from './NavBarTest';
import Footer from './Footer';

const Getinvolved = () => {
  return (
    <div>
      <HeaderTemp />
      <NavBarTest />
        <h1>GetInvolved Page</h1>
      <Footer />
  </div>
  );
};

export default Getinvolved;
