import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import '../TeamMembers.css';

const ourDirectors = [
    {
        name: 'Mathurshi Vijayarajenddran',
        title: 'Director Operation',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717855486/WhatsApp_Image_2024-06-05_at_20.14.00_c4aqkk.jpg',
        linkedin: 'https://www.linkedin.com/in/mathurshi/'
    },
    {
        name: 'Dunston Anthonypillai',
        title: 'Director Finance',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717129297/Dany_cceofn.jpg',
        linkedin: 'https://www.linkedin.com/in/dunston-lionel/'
    },
    {
        name: 'Chandrakumar Rachan',
        title: 'Director Projects',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717855497/WhatsApp_Image_2024-06-05_at_20.14.10_mg8cvu.jpg',
        linkedin: 'https://www.linkedin.com/in/racshan-kannan-1b048721b/'
    },
    {
        name: 'Thakshana Selvakumar',
        title: 'Director OutReach',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081213/team_11_xcx5fh.jpg',
        linkedin: 'https://www.linkedin.com/in/thakshanah-selvakumar/'
    },
    {
    name: 'Zamith Ahamed',
    title: 'Director iHub',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717923839/zamith_ffkrqc.jpg',
    linkedin: 'https://www.linkedin.com/in/zamithahamed/'
    },
    {
        name: 'Lavanya Ravichandran',
        title: 'Director SheRise',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717923850/lavanya_dpk25z.jpg',
        linkedin: 'https://www.linkedin.com/in/lavanya-ravichadran/'
    },

];

const SeniorManagementTeam = () => {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <center><h5 className="mb-3">Senior Management Team</h5></center>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {ourDirectors.map((director, index) => (
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 p-1 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                                <div className="team-item position-relative rounded overflow-hidden">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src={director.image} alt={director.name} style={{ minHeight: 250 }} />
                                    </div>
                                    <div className="team-text bg-light text-center p-3">
                                        <h5>{director.name}</h5>
                                        <p className="text-primary mb-1">{director.title}</p>
                                        <div className="team-social text-center">
                                            <a className="btn btn-square" href={director.linkedin}><i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeniorManagementTeam;
