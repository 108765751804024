import React, { useEffect } from 'react';
import Footer from '../Footer';
import Carousal from './Carousal';
import Icons from './icons';
import Ourcauses from './OurCauses';
import Contact from './Contact';
import HeaderTemp from '../HeaderTemp';
import OurStory from './OurStory';
import AboutTeam from './AboutTeam';
import MakeImpact from './MakeImpact';
import NavBarTest from '../NavBarTest';
import WhatWeDo from './WhatWeDo';
import OurDirectors from './OurDirectors';
import Partners from './Partners';
import OurImpactVentures from './OurImpactVentures';

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HeaderTemp /> 
      <NavBarTest/>
        <Carousal /> 
        <Icons />  
        <OurStory />  
        <Partners />
        {/* <AboutTeam /> */}
        <OurDirectors />
        {/* <TeamMembers /> */}
        <MakeImpact />
        <Ourcauses /> 
        <OurImpactVentures/>
        {/* <WhatWeDo /> */}
        <Contact />   
      <Footer />
    </div>
  );
};

export default Home;
