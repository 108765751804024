import React, { useState }  from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaGoogle, FaWhatsapp, FaHandsHelping, FaHandHoldingUsd, FaCalendarCheck } from 'react-icons/fa';
import { FaHome, FaEnvelope, FaPhone} from 'react-icons/fa';
import logo from './Logo.png';
import './Footer.css';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View,Table, TableCell, TableHeader ,Image, StyleSheet } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const [showCardMessage, setShowCardMessage] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    setShowCardMessage(true);
    // window.location.href = '/donateus';
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleAboutClick = () => {
    navigate('/about');
  };

  const handleContactClick = () => {
    navigate('/ContactPage');
  };

  const handleProjectsClick = () => {
    navigate('/projects');
  };

  const handleEventsClick = () => {
    navigate('/events');
  };

  const handleVolunteerClick = () => {
    navigate('/VolunteerTest');
  };

  const handleAppointmentClick = () => {
    navigate('/VisitingAppointment');
  };

  const handleCloseCardMessage = () => {
    navigate('/events');
    setShowCardMessage(false);
    // navigate('/DonateOnUSD'); // Use the `useNavigate` hook to navigate to '/DonateUsTest'
  };

  return (
    <MDBFooter style={{ backgroundColor: 'rgb(1, 99, 172)', color: 'white' }} className='text-center text-lg-start'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span style={{ color: 'white' }}>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/eegaisrilanka' className='me-4 text-reset'>
            <FaFacebook style={{ color: 'white', fontSize: '24px' }} />
          </a>
          <a href='https://twitter.com/eegaisrilanka?lang=en' className='me-4 text-reset'>
            <FaTwitter style={{ color: 'white', fontSize: '24px' }} />
          </a>
          <a href='https://g.co/kgs/nsSLRN' className='me-4 text-reset'>
            <FaGoogle style={{ color: 'white', fontSize: '24px' }} />
          </a>
          <a href='https://instagram.com/eegaisrilanka' className='me-4 text-reset'>
            <FaInstagram style={{ color: 'white', fontSize: '24px' }} />
          </a>
          <a href='https://lk.linkedin.com/company/eegai-srilanka' className='me-4 text-reset'>
            <FaLinkedin style={{ color: 'white', fontSize: '24px' }} />
          </a>
          <a href='https://api.whatsapp.com/send?phone=0094763270273&text=Hello%20there,%20I%20have%20a%20question' className='me-4 text-reset'>
            <FaWhatsapp style={{ color: 'white', fontSize: '24px' }} />
          </a>
        </div>
      </section>

      <section>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <div className="logo" style={{ textAlign: 'left' }}>
                <img src={logo} alt="Logo" style={{ width: '150px', height: 'auto' }} />
              </div>
              <br /><br />
              <p style={{ color: 'white' }}>
                "Giving is not just about making a donation, it's about making a difference."
              </p>
              <br />
              <p>
                <span style={{ marginRight: '20px' }}></span>
                <span onClick={handleClick} style={{ cursor: 'pointer' }}> <FaHandHoldingUsd style={{ color: 'white', fontSize: '30px', marginRight: '10px' }} /></span>
                <span onClick={handleVolunteerClick} style={{ cursor: 'pointer' }}> <FaHandsHelping style={{ color: 'white', fontSize: '30px', marginRight: '10px' }} /></span>
                <span onClick={handleAppointmentClick} style={{ cursor: 'pointer' ,color: 'white'}}> <FaCalendarCheck style={{ color: 'white', fontSize: '20px' }} /></span> 
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white' }}>Useful links</h6>
              <p style={{ color: 'white' }}>
                <a onClick={handleHomeClick} className='text-reset' style={{ color: 'white' }}>
                  Home
                </a>
              </p>
              <p style={{ color: 'white' }}>
                <a onClick={handleAboutClick}  className='text-reset' style={{ color: 'white' }}>
                  About Us
                </a>
              </p>
              <p style={{ color: 'white' }}>
                <a onClick={handleContactClick} className='text-reset' style={{ color: 'white' }}>
                  Contact Us
                </a>
              </p>
              <p style={{ color: 'white' }}>
                <a onClick={handleProjectsClick} className='text-reset' style={{ color: 'white' }}>
                  Projects
                </a>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white' }}>Useful links</h6>
              <p style={{ color: 'white' }}>
                <a onClick={handleEventsClick} className='text-reset' style={{ color: 'white' }}>
                  Events
                </a>
              </p>
              <p style={{ color: 'white' }}>
                <a onClick={handleVolunteerClick} className='text-reset' style={{ color: 'white' }}>
                  Get Involved
                </a>
              </p>
              <p style={{ color: 'white' }}>
                <a onClick={handleAppointmentClick} className='text-reset' style={{ color: 'white' }}>
                  Get Appointment
                </a>
              </p>
              {/* <p style={{ color: 'white' }}>
                <a href='/projects' className='text-reset' style={{ color: 'white' }}>
                  Donate Us
                </a>
              </p> */}
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white' }}>Contact</h6>
              <p style={{ color: 'white' }}>
                <FaHome color='white' className='me-2' />
                No. 297 , Kanniya Road , Trincomalee
              </p>
              <p style={{ color: 'white' }}>
                <FaEnvelope color='white' className='me-3' />
                info@eegaisrilanka.org
              </p>
              <p style={{ color: 'white' }}>
                <FaPhone color='white' className='me-3' /> +94 772927516
              </p>
              <p style={{ color: 'white' }}>
                {/* <FaPhone color='white' className='me-3' />  */}
                <span style={{ marginRight: '40px' }}></span>+94 763270273
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgb(0, 71, 122)' }}>
        <p style={{ color: 'white' }}>
          © 2024 eegaisrilanka.org. All rights reserved.
        </p>
        {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows,</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Branch Code:</strong> 414<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                  {pdfContent && (
                    <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Generating PDF...' : 'Download Receipt'
                      }
                    </PDFDownloadLink>
                  )} 
                </div>
            </MDBCardBody>
            <MDBCardFooter>  
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}
      </div>
    </MDBFooter>
  );
};

export default Footer;
