import React, { useState } from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, Table, TableCell, TableHeader, Image, StyleSheet } from '@react-pdf/renderer';
import { justify } from '@cloudinary/url-gen/qualifiers/textAlignment';

const causes = [
  {
    id: 1,
    projectName: "Find Me",
    projectDescription: "Findme bridges the gap between skilled professionals and those in need of their expertise, combating unemployment and fostering economic growth through a dynamic, inclusive marketplace.",
    coverPhotoReference: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717924385/Findme_matpiy.jpg"
  },
  {
    id: 2,
    projectName: "She Rise",
    projectDescription: "Sherise is dedicated to fostering female leadership and innovation, creating a global community where women thrive and drive transformative social impact.",
    coverPhotoReference: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717923594/WhatsApp_Image_2024-06-06_at_13.40.02_pj9gsi.jpg"
  },
  {
    id: 3,
    projectName: "Digitera Labs",
    projectDescription: "Digitera Labs empowers businesses and communities by crafting bespoke digital solutions, enhancing their online presence, and driving sustainable growth in the digital era.",
    coverPhotoReference: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717923623/logo_2_copy_lcnqpu.png"
  }
];

const OurImpactVentures = () => {
  const [showCardMessage, setShowCardMessage] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);

  const handleClick = () => {
    setShowCardMessage(true);
  };

  const handleCloseCardMessage = () => {
    setShowCardMessage(false);
  };

  return (
    <div>
      <section className="section-padding" id="section_3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 text-center mb-4">
              <h2>Our Impact Ventures</h2>
            </div>
            {causes.map(cause => (
              <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" key={cause.id}>
                <div className="custom-block-wrap" style={{ marginBottom: "50px" }}>
                  <img style={{ height: "240px" }} src={cause.coverPhotoReference} className="custom-block-image img-fluid" alt="" />
                  <div className="custom-block">
                    <div className="custom-block-body" style={{ height: "200px" }}>
                      <h5 className="mb-3">{cause.projectName}</h5>
                      <p style={{ textAlign: 'justify' }}>{cause.projectDescription}</p>
                    </div>
                    <a href="#" className="custom-btn btn">Learn More</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows,</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                {pdfContent && (
                  <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                    {({ blob, url, loading, error }) =>
                      loading ? 'Generating PDF...' : 'Download Receipt'
                    }
                  </PDFDownloadLink>
                )}
              </div>
            </MDBCardBody>
            <MDBCardFooter>
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}
    </div>
  );
};

export default OurImpactVentures;
