import React, { useState } from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import avatar1 from '../images/avatar/pretty-blonde-woman-wearing-white-t-shirt.jpg';
import emailjs from 'emailjs-com';
import axios from 'axios'; 
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaGoogle, FaWhatsapp, FaHandsHelping, FaHandHoldingUsd } from 'react-icons/fa';

const Contact = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [purpose, setPurpose] = useState('');
    const [errors, setErrors] = useState({});
    const [notification, setNotification] = useState('');

    const validateForm = () => {
        const errors = {};
    
        if (!isValidEmail(email)) {
          errors.email = 'Invalid email format';
        }
    
        setErrors(errors);
    
        return Object.keys(errors).length === 0;
      };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (validateForm()) {
          const formData = {
            firstname,
            lastname,
            email,
            purpose,
          };
    
          // Save data to the database using an API call
          axios.post(process.env.REACT_APP_BACKEND_URL + '/api/contact', formData)
            .then((response) => {
              // Handle the response if needed (e.g., show success message)
              console.log('Data successfully saved:', response.data);
    
              // Send email notification to the contact person
              const params = {
                to_email: formData.email,
                to_name: `${formData.firstname} ${formData.lastname}`,
                // Add more parameters if needed for your email template
              };
    
              // Replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' with actual values from your emailjs account
              emailjs.send('service_sgga8tj', 'template_x3x1mtm', params, 'nGaQaEmTz4KIhXJig')
                .then((result) => {
                  console.log(result.text);
                  setFirstname('');
                  setLastname('');
                  setEmail('');
                  setPurpose('');
                  setNotification('Thank You for Contacting Us!');
                })
                .catch((error) => {
                  console.log(error.text);
                  setNotification('An error occurred while sending the email.');
                });
            })
            .catch((error) => {
              // Handle errors if any
              setNotification('An error occurred while saving data.');
              console.error('Error saving data:', error);
            });
        }
      };


      // Validation helper functions
    const isValidEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };
  
  return (
    <div>
      <section class="contact-section section-padding" id="section_6">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-4 col-12 ms-auto mb-5 mb-lg-0">
                            <div class="contact-info-wrap">
                                <h2>Get in touch</h2>

                                {/* <div class="contact-image-wrap d-flex flex-wrap">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699550442/pretty-blonde-woman-wearing-white-t-shirt_lst278.jpg" class="img-fluid avatar-image" alt=""/>

                                    <div class="d-flex flex-column justify-content-center ms-3">
                                        <p class="mb-0">Karmegam Nishanthan</p>
                                        <p class="mb-0"><strong>Co-Founder & CEO</strong></p>
                                    </div>
                                </div> */}

                                <div class="contact-info">
                                    {/* <h5 class="mb-3">Contact Infomation</h5> */}

                                    <p class="d-flex mb-2">
                                        <i class="bi-geo-alt me-2"></i>
                                        No. 297 , Kanniya Road , Trincomalee
                                    </p>

                                    <p class="d-flex mb-2">
                                        <i class="bi-telephone me-2"></i>

                                        <a href="tel: 0094 772927516">
                                            +94 772927516
                                        </a>
                                    </p>

                                    <p class="d-flex">
                                        <i class="bi-envelope me-2"></i>

                                        <a href="mailto:info@eegaisrilanka.org">
                                            info@eegaisrilanka.org
                                        </a>
                                    </p>

                                    <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                                    {/* <div className='me-5 d-none d-lg-block'>
                                      <span style={{ color: '#0163AC' }}>Get connected with us on social networks:</span>
                                    </div> */}

                                    <div>
                                      <a href='https://www.facebook.com/eegaisrilanka' className='me-4 text-reset'>
                                        <FaFacebook style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                      <a href='https://twitter.com/eegaisrilanka?lang=en' className='me-4 text-reset'>
                                        <FaTwitter style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                      <a href='https://g.co/kgs/nsSLRN' className='me-4 text-reset'>
                                        <FaGoogle style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                      <a href='https://instagram.com/eegaisrilanka' className='me-4 text-reset'>
                                        <FaInstagram style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                      <a href='https://lk.linkedin.com/company/eegai-srilanka' className='me-4 text-reset'>
                                        <FaLinkedin style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                      <a href='https://api.whatsapp.com/send?phone=0094763270273&text=Hello%20there,%20I%20have%20a%20question' className='me-4 text-reset'>
                                        <FaWhatsapp style={{ color: '#0163AC', fontSize: '24px' }} />
                                      </a>
                                    </div>
                                  </section>

                                    {/* <a href="/RequestVisitingAppointment" class="custom-btn btn mt-3">Get Direction</a> */}
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-12 mx-auto">
                            <center> {notification && <p>{notification}</p>} </center>
                           
                            <form class="custom-form contact-form" action="#" method="post" role="form" onSubmit={handleSubmit}>
                                <h2>Contact form</h2>

                                {/* <p class="mb-4">Or, you can just send an email:
                                    <a href="#">info@eegaisrilanka.org</a>
                                </p> */}
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <input type="text" name="first-name" id="first-name" class="form-control" placeholder="First Name"  value={firstname}  onChange={(e) => setFirstname(e.target.value)} required/>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-12">
                                        <input type="text" name="last-name" id="last-name" class="form-control" placeholder="Last Name" value={lastname}  onChange={(e) => setLastname(e.target.value)} required/>
                                    </div>
                                </div>

                                <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}required/>

                                <textarea name="message" rows="5" class="form-control" id="message" placeholder="How can we help you?" value={purpose}  onChange={(e) => setPurpose(e.target.value)} ></textarea>

                                <button type="submit" class="form-control">Send Message</button>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
    </div>
  );
};

export default Contact;
