import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import slide2 from '../images/causes/poor-child-landfill-looks-forward-with-hope.jpg';
import slide3 from '../images/causes/african-woman-pouring-water-recipient-outdoors.jpg';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View,Table, TableCell, TableHeader ,Image, StyleSheet } from '@react-pdf/renderer';

const Ourcauses = () => {

    const [projects, setProjects] = useState([]);
    const [showCardMessage, setShowCardMessage] = useState(false);
    const [pdfContent, setPdfContent] = useState(null);

    const handleClick = () => {
      setShowCardMessage(true);
    };
    
    const handleCloseCardMessage = () => {
      setShowCardMessage(false);
    };
  
   
       useEffect(() => {
           // Fetch project data from the server
           axios.get(process.env.REACT_APP_BACKEND_URL + '/projects')
             .then(response => {
               // console.log(response.deta)
               const { data } = response;
               setProjects(data);
               console.log(projects)
             })
             .catch(error => {
               console.error('Error fetching project data:', error);
             });
         }, []);   
       
     return (
       <div>
       <section class="section-padding" id="section_3">
         <div class="container">
             <div class="row">
   
                 <div class="col-lg-12 col-12 text-center mb-4">
                     <h2>Our Causes</h2>
                 </div>
   
         {projects.slice(0, 3).map(project => (
           <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" key={project.id}>
             <div className="custom-block-wrap" style={{marginBottom:"50px"}}>
               <img style={{height:"200px"}} src={project.coverPhotoReference} className="custom-block-image img-fluid" alt="" />
   
               <div className="custom-block">
                 <div className="custom-block-body" style={{height:"200px"}}>
                   <h5 className="mb-3">{project.projectName}</h5>
   
   
                   <p>{project.projectDescription}</p>
  
                 </div>
   
                 <a onClick={handleClick} className="custom-btn btn">Donate now</a>
               </div>
             </div>
           </div>
         ))}
               </div>
           </div>
       </section>
       {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows,</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                  {pdfContent && (
                    <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Generating PDF...' : 'Download Receipt'
                      }
                    </PDFDownloadLink>
                  )} 
                </div>
            </MDBCardBody>
            <MDBCardFooter>  
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}

     </div>
     );
   };

export default Ourcauses;
