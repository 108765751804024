import React, { useState } from 'react';
import { FaHandsHelping, FaHandHoldingUsd, FaCalendarCheck, FaUser, FaEnvelope } from 'react-icons/fa';
import './css/bootstrap.min.css';
import './css/bootstrap-icons.css';
import './css/templatemo-kind-heart-charity.css';
import { useNavigate } from 'react-router-dom';

import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

import { PDFDownloadLink, Document, Page, Text, View } from '@react-pdf/renderer';

const HeaderTemp = () => {
  const [showCardMessage, setShowCardMessage] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);

  const navigate = useNavigate();

  const handleClick = () => {
    setShowCardMessage(true);
  };

  const handleVolunteerClick = () => {
    navigate('/VolunteerTest');
  };

  const handleAppoinmentClick = () => {
    navigate('/VisitingAppointment');
  };

  const handleSignUpClick = () => {
    navigate('/Register');
  };

  const handleSignInClick = () => {
    navigate('/SignIn');
  };

  const handleCloseCardMessage = () => {
    setShowCardMessage(false);
  };

  return (
    <div>
      <style>
        {`
          .hover-effect-button:hover {
            color: black;
          }
          .icon-container {
            display: inline-block;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            color: #0163AC;
          }
          .icon-text {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            background: rgba(255, 255, 255, 0.75);
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 14px;
            color: #0163AC;
          }
          .icon-container:hover .icon-text {
            visibility: visible;
            opacity: 1;
          }
          @media (max-width: 991px) {
            .site-header .social-icon {
              display: flex;
              justify-content: center;
              margin-top: 10px;
            }
          }
        `}
      </style>
      <header className="site-header" style={{ backgroundColor: '#E5E4E2' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 d-flex flex-wrap">
            <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717925506/Untitled_design_zs9qrp.png" alt="iHub" style={{ height: '30px', marginRight: '10px' }} />
              <p className="d-flex me-4 mb-0">
                <i className="bi-geo-alt me-2"></i>
                <b>
                No. 297, Kanniya Road, Trincomalee
                </b>
              </p>
              <p className="d-flex mb-0">
                <i className="bi-envelope me-2"></i>
                {/* <FaEnvelope style={{ fontSize: '20px', marginRight: '10px' }} /> */}
                <b><a href="mailto:info@eegaisrilanka.org">info@eegaisrilanka.org</a></b>
              </p>
            </div>
            <div className="col-lg-3 col-12 ms-auto d-lg-block d-flex justify-content-center align-items-center">
              <ul className="social-icon">
                <span onClick={handleClick} className="icon-container">
                  <span className="icon-text">Donate</span>
                  <FaHandHoldingUsd style={{ fontSize: '28px', marginRight: '10px' }} />
                </span>
                <span onClick={handleVolunteerClick} className="icon-container">
                  <span className="icon-text" style={{background:'white'}}>Become a Volunteer</span>
                  <FaHandsHelping style={{ fontSize: '25px' }} />
                </span>
                <span onClick={handleAppoinmentClick} className="icon-container">
                  <span className="icon-text">Get Appointment</span>
                  <FaCalendarCheck style={{ fontSize: '20px' }} />
                </span>
                <span onClick={handleSignUpClick} className="icon-container">
                  <span >SignUp</span>
                </span>
                <span onClick={handleSignInClick} className="icon-container">
                  <span >SignIn</span>
                </span>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows:</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Branch Code:</strong> 414<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                {pdfContent && (
                  <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                    {({ blob, url, loading, error }) =>
                      loading ? 'Generating PDF...' : 'Download Receipt'
                    }
                  </PDFDownloadLink>
                )}
              </div>
            </MDBCardBody>
            <MDBCardFooter>
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}
    </div>
  );
};

export default HeaderTemp;