import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import '../TeamMembers.css';

const teamMembers = [
  {
    name: 'Leena Sritharan',
    title: 'Event Coordinator',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081209/team_1_es57jc.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' },
      { icon: 'fab fa-linkedin', link: '' }
    ]
  },
  {
    name: 'Shishera Kamalan',
    title: 'Gender Equality Advocate',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081207/team_2_vtlxcb.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  },
  {
    name: 'Satheera Kamalan',
    title: 'Women\'s Rights Activist',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081208/team_3_hylcxs.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  },
  {
    name: 'Kali Pakiyanathan',
    title: 'Donor Relations Associate',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081208/team_4_v6puho.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  },
  {
    name: 'Balachandran Vinojan',
    title: 'Fundraising Champion',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081209/team_6_j2xhdi.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  },
  {
    name: 'Deepika Vinojan',
    title: 'Outreach Ambassador',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081209/team_5_c1w11y.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  },
  {
    name: 'Rajaratnam Dilshan',
    title: 'Research Specialist',
    image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717081213/team_12_hd96th.jpg',
    social: [
      { icon: 'fab fa-facebook-f', link: '' },
      { icon: 'fab fa-twitter', link: '' },
      { icon: 'fab fa-instagram', link: '' }
    ]
  }
];

const TeamMembers = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
        <center><h5 className="mb-3">Project Coordinators</h5></center>
        </div>
        <div className="row g-4">
          {teamMembers.map((member, index) => (
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img className="img-fluid" src={member.image} alt={member.name} style={{ minHeight: 300 }} />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>{member.name}</h5>
                  {/* <p className="text-primary">{member.title}</p> */}
                  {/* <div className="team-social text-center">
                    {member.social.map((social, idx) => (
                      <a key={idx} className="btn btn-square" href={social.link}>
                        <i className={social.icon}></i>
                      </a>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
