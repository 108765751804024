import React from "react";
import StaffSideBar from "./StaffSideBar";

function StaffDashboard() {
  return (
    <div>
      <StaffSideBar />
    </div>
  );
}
export default StaffDashboard;