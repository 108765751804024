import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { MuiTelInput } from 'mui-tel-input';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const options = [
  { value: 'Communication', label: 'Communication' },
  { value: 'Leadership', label: 'Leadership' },
  { value: 'TeamWork', label: 'TeamWork' },
];

const interestOptions = [
  { value: 'Social Work', label: 'Social Work' },
  { value: 'English', label: 'English' },
  { value: 'IT', label: 'IT' }
  
];

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.eegaisrilanka.org/">
        eegai.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function VolunteerTest() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState(null);
  const [contactNo, setContactNo] = useState('');
  const [location, setLocation] = useState('');
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [cvFile, setCVFile] = useState(null);
  const [cvFilename, setCVFilename] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [showCardMessage, setShowCardMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const saveFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (!['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
        setFileName('');
        console.error('Invalid file type. Please select an image file.');
        setErrors({ profilePic: 'Invalid file type. Please select an image file.' });
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      console.error('No file selected');
      setErrors({ profilePic: 'No file selected.' });
    }
  };

  const checkUsernameAvailability = () => {
    if (username.trim() === '') {
      return;
    }
    axios
      .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
      .then((response) => {
        setIsUsernameTaken(response.data.isTaken);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateForm = () => {
    const errors = {};
    if (username.trim() === '') {
      errors.username = 'Username is required';
    } else if (username.length < 3) {
      errors.username = 'Username should be at least 3 characters';
    } else if (!/^[A-Za-z][A-Za-z0-9_]{7,29}$/.test(username)) {
      errors.username = 'Invalid username format. Username should start with an alphabet and can contain alphabets, numbers, or an underscore. Length should be 8-30 characters.';
    }
    if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }
    if (password.length < 6) {
      errors.password = 'Password should be at least 6 characters';
    }
    if (!/\d/.test(password)) {
      errors.password = 'Password should contain at least one digit';
    }
    if (!/[a-zA-Z]/.test(password)) {
      errors.password = 'Password should contain at least one letter';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.password = 'Password should contain at least one special character (!@#$%^&*)';
    }
    if (!isValidContactNo(contactNo)) {
      errors.contactNo = 'Invalid contact number format';
    }
    if (location.trim() === '') {
      errors.location = 'Home Town is required';
    }
    if (skills.length === 0) {
      errors.skills = 'Skills is required';
    }
    if (interests.length === 0) {
      errors.interests = 'Interests is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append('cvFile', cvFile);
    formData.append("cvFilename", cvFilename);
    if (validateForm()) {
      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("dob", dob);
      formData.append("location", location);
      formData.append("contactNo", contactNo);
      formData.append("skills", JSON.stringify(skills));
      formData.append("interests", JSON.stringify(interests));
      console.log(formData);
      axios
        .post('https://eegaibackend.onrender.com/api/checkEmail', { email })
        .then((response) => {
          if (response.data.isRegistered) {
            setErrors({ email: 'This email is already registered' });
          } else {
            axios
              .post('https://eegaibackend.onrender.com/api/checkUsername', { username })
              .then((response) => {
                if (response.data.isTaken) {
                  setErrors({ username: 'This username is already taken' });
                } else {
                  axios
                    .post('https://eegaibackend.onrender.com/api/submitForm', formData)
                    .then((response) => {
                      console.log(response.data);
                      setUsername('');
                      setEmail('');
                      setPassword('');
                      setDob(null);
                      setContactNo('');
                      setSkills([]);
                      setInterests([]);
                      setErrors({});
                      setShowCardMessage(true);
                      setOpen(true);
                      setLocation('');
                      setFile(null);
                      setFileName('');
                      setCVFile(null);
                      setCVFilename('');
                      navigate('/');
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const isValidContactNo = (contactNo) => {
    return isValidPhoneNumber(contactNo);
  };

  const handleSkillsChange = (event) => {
    const selectedSkills = event.target.value;
    setSkills(selectedSkills);
  };

  const handleInterestsChange = (event) => {
    const selectedInterests = event.target.value;
    setInterests(selectedInterests);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://res.cloudinary.com/dhzvwxfod/image/upload/v1717156065/1_qn3gbt.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'rgb(1, 99, 172)' }}>
              <VolunteerActivismIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Volunteer Form
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="username"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoFocus
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={checkUsernameAvailability}
                    error={Boolean(errors.username) || isUsernameTaken}
                    helperText={isUsernameTaken ? 'Username is already taken' : errors.username}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      value={dob}
                      onChange={(newValue) => setDob(newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <MuiTelInput
                    required
                    fullWidth
                    value={contactNo}
                    onChange={(newValue) => setContactNo(newValue)}
                    defaultCountry="US"
                    id="contactNo"
                    label="Contact No"
                    error={Boolean(errors.contactNo)}
                    helperText={errors.contactNo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="location"
                    label="Home Town"
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    error={Boolean(errors.location)}
                    helperText={errors.location}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="skills-label">Skills</InputLabel>
                  <Select
                    labelId="skills-label"
                    id="skills"
                    multiple
                    value={skills}
                    onChange={handleSkillsChange}
                    renderValue={(selected) => selected.join(', ')}
                    fullWidth
                    error={Boolean(errors.skills)}
                    helperText={errors.skills}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="interests-label">Interests</InputLabel>
                  <Select
                    labelId="interests-label"
                    id="interests"
                    multiple
                    value={interests}
                    onChange={handleInterestsChange}
                    renderValue={(selected) => selected.join(', ')}
                    fullWidth
                    error={Boolean(errors.interests)}
                    helperText={errors.interests}
                  >
                    {interestOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    <CloudUploadIcon sx={{ mr: 1 }} />
                    Upload Profile Picture
                    <input
                      type="file"
                      hidden
                      onChange={saveFile}
                    />
                  </Button>
                  {fileName && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {fileName}
                    </Typography>
                  )}
                  {errors.profilePic && (
                    <Typography variant="body2" color="error">
                      {errors.profilePic}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                  >
                    <CloudUploadIcon sx={{ mr: 1 }} />
                    Upload CV
                    <input
                      type="file"
                      hidden
                      onChange={(e) => {
                        setCVFile(e.target.files[0]);
                        setCVFilename(e.target.files[0]?.name || '');
                      }}
                    />
                  </Button>
                  {cvFilename && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {cvFilename}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
              {showCardMessage && (
                <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                  <Alert onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Volunteer created successfully!
                  </Alert>
                </Snackbar>
              )}
            </Box>
          </Box>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
