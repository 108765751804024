import React, { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiTelInput } from 'mui-tel-input';
import Link from '@mui/material/Link';

const VisitingAppointment = () => {
  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [doa, setDate] = useState(null);
  const [toa, setTime] = useState(null);
  const [purpose, setPurpose] = useState('');
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);



  const validateForm = () => {
    const errors = {};

    if (fullname.trim() === '') {
      errors.fullname = 'Fullname is required';
    }

    if (email.trim() === '') {
      errors.email = 'Email is required';
    }

    if (!emailIsValid(email)) {
      errors.email = 'Invalid email format';
    }

    if (contactNo.trim() === '') {
      errors.contactNo = 'Contact Number is required';
    } else if (!isValidPhoneNumber(contactNo)) {
      errors.contactNo = 'Invalid contact number format';
    }

    if (doa === null) {
      errors.doa = 'Date of appointment is required';
    }

    if (toa === null) {
      errors.toa = 'Time of appointment is required';
    }

    if (purpose.trim() === '') {
      errors.purpose = 'Purpose is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleContactNoChange = (value) => {
    setContactNo(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = {
        fullname,
        email,
        contactNo,
        doa,
        toa,
        purpose,
      };

      axios
        .post('https://eegaibackend.onrender.com/submit-visitingAppointment', data)
        .then((response) => {
          console.log(response.data);
          setNotification('Thank You for Contacting Us!');
          setOpen(true);
          navigate('/');
        })
        .catch((error) => {
          console.error(error);
          setError('An error occurred while submitting your request. Please try again later.');
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://res.cloudinary.com/dhzvwxfod/image/upload/v1717148707/6_fu0y46.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'rgb(1, 99, 172)' }}>
              <VolunteerActivismIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Request Visiting Appointment
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                label="Full Name"
                variant="outlined"
                margin="normal"
                fullWidth
                id="fullname"
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
              {errors.fullname && <Alert variant="outlined" severity="error">{errors.fullname}</Alert>}

              <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <Alert variant="outlined" severity="error">{errors.email}</Alert>}

              <MuiTelInput
                defaultCountry="LK"
                label="Contact Number"
                value={contactNo}
                onChange={handleContactNoChange}
                style={{ width: '100%', marginTop: '16px' }}
                required
              />
              {errors.contactNo && <Alert variant="outlined" severity="error">{errors.contactNo}</Alert>}
              <br></br><br></br>

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Appointment"
                  value={doa}
                  onChange={(newValue) => setDate(newValue)}
                  required
                />
              </LocalizationProvider>
              {errors.doa && <Alert variant="outlined" severity="error">{errors.doa}</Alert>}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Time of Appointment"
                  value={toa}
                  onChange={(newValue) => setTime(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  required
                />
              </LocalizationProvider>
              {errors.toa && <Alert variant="outlined" severity="error">{errors.toa}</Alert>} */}

                <Box sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}   >
                  <DatePicker
                    label="Date of Appointment"
                    value={doa}
                    onChange={(newValue) => setDate(newValue)}
                    required
                  />
                </LocalizationProvider>
                {errors.doa && <Alert variant="outlined" severity="error">{errors.doa}</Alert>}
              </Box>

              <Box sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time of Appointment"
                    value={toa}
                    onChange={(newValue) => setTime(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    required
                  />
                </LocalizationProvider>
                {errors.toa && <Alert variant="outlined" severity="error">{errors.toa}</Alert>}
              </Box>

              <TextField
                label="Purpose"
                variant="outlined"
                margin="normal"
                fullWidth
                id="purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                required
                multiline
                rows={4}
              />
              {errors.purpose && <Alert variant="outlined" severity="error">{errors.purpose}</Alert>}

              {error && <Alert variant="outlined" severity="error">{error}</Alert>}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>

              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  {notification}
                </Alert>
              </Snackbar>

              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">Forgot password?</Link> */}
                </Grid>
                <Grid item>
                  <Link href="/SignIn" variant="body2">
                    {"Already have an account? Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default VisitingAppointment;
