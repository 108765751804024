import React, { useState }  from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import hands from "../images/icons/hands.png";
import heart from "../images/icons/heart.png";
import receive from "../images/icons/receive.png";
import schlorship from "../images/icons/scholarship.png";
import connect from "../images/icons/connect.png";
import { useNavigate } from "react-router-dom";
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBBtn
  } from 'mdb-react-ui-kit';
  
  import { PDFDownloadLink, PDFViewer, Document, Page, Text, View,Table, TableCell, TableHeader ,Image, StyleSheet } from '@react-pdf/renderer';
  

const Icons = () => {

    const [showCardMessage, setShowCardMessage] = useState(false);
    const [pdfContent, setPdfContent] = useState(null);
    const navigate = useNavigate();

    const handleClick = () => {
        setShowCardMessage(true);
        // 1
      };

      const handleCloseCardMessage = () => {
        setShowCardMessage(false);
        // navigate('/DonateOnUSD'); // Use the `useNavigate` hook to navigate to '/DonateUsTest'
      };

      const handleVolunteerClick = () => {
        navigate('/VolunteerTest');
        // navigate('/DonateOnUSD'); // Use the `useNavigate` hook to navigate to '/DonateUsTest'
      };

      const handleContactClick = () => {
        navigate('/ContactPage');
        // navigate('/DonateOnUSD'); // Use the `useNavigate` hook to navigate to '/DonateUsTest'
      };

      const handleIHubClick = () => {
        navigate('/iHub');
        // navigate('/DonateOnUSD'); // Use the `useNavigate` hook to navigate to '/DonateUsTest'
      };
    
    
  return (
    <div>
        <section class="section-padding">
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-10 col-12 text-center mx-auto">
                            <h2 class="mb-5">Welcome to EEGAI's Official Page</h2>
                        </div>

            

                        <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" >
                            <div class="featured-block d-flex justify-content-center align-items-center">
                                <a onClick={handleVolunteerClick} class="d-block">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699548188/hands_etmhnr.png" class="featured-block-image img-fluid" alt=""/>

                                    <p class="featured-block-text">Become a <strong>volunteer</strong></p>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                            <div class="featured-block d-flex justify-content-center align-items-center">
                                <a onClick={handleClick} class="d-block">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699548195/receive_bznzok.png" class="featured-block-image img-fluid" alt=""/>

                                    <p class="featured-block-text">Make a <strong>Donation</strong></p>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4" >
                            <div class="featured-block d-flex justify-content-center align-items-center">
                                <a onClick={handleContactClick}  class="d-block">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717058170/Connect_with_Us_yjbvei.jpg" class="featured-block-image img-fluid" alt="" style={{ maxHeight: '140px', maxWidth : '200px'}}/>

                                    <p class="featured-block-text"><strong>Connect</strong> With Us</p>
                                </a>
                            </div>
                        </div>

                        {/* <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4" >
                            <div class="featured-block d-flex justify-content-center align-items-center">
                                <a href="/iHub"  onClick={handleIHubClick}  class="d-block">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699548188/connect_hgitg6.png" class="featured-block-image img-fluid" alt="" style={{ maxHeight: '200px', maxWidth : '200px'}}/>

                                    <p class="featured-block-text"><strong>Become a</strong> Trainee</p>
                                </a>
                            </div>
                        </div> */}


                        {/* <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <div class="featured-block d-flex justify-content-center align-items-center">
                                <a href="donate.html" class="d-block">
                                    <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1699548190/heart_hq9kqu.png" class="featured-block-image img-fluid" alt=""/>

                                    <p class="featured-block-text"><strong>Become a</strong> Trainee</p>
                                </a>
                            </div>
                        </div>
                       */}


                    </div>
                </div>
            </section>

            {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows,</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Branch Code:</strong> 414<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                  {pdfContent && (
                    <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Generating PDF...' : 'Download Receipt'
                      }
                    </PDFDownloadLink>
                  )} 
                </div>
            </MDBCardBody>
            <MDBCardFooter>  
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}

  </div>
  );
};

export default Icons;
