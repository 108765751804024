import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import NavBar from './NavBar';
import HeaderTemp from '../HeaderTemp';
import Footer from '../Footer';
import NavBarTest from '../NavBarTest';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View,Table, TableCell, TableHeader ,Image, StyleSheet } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn
} from 'mdb-react-ui-kit';



const Events = () => {

  const [showCardMessage, setShowCardMessage] = useState(false);
  const [pdfContent, setPdfContent] = useState(null);
 
  const handleClick = () => {
    setShowCardMessage(true);
  };
  
  const handleCloseCardMessage = () => {
    setShowCardMessage(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy'); // e.g., September 21, 2024
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'p'); // e.g., 12:00 AM
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    const [events, setEvents] = useState([]);
    useEffect(() => {
        // Fetch project data from the server
        axios.get(process.env.REACT_APP_BACKEND_URL + '/events')
          .then(response => {
            console.log(response.deta)
            const { data } = response;
            setEvents(data);
            console.log(events)
          })
          .catch(error => {
            console.error('Error fetching event data:', error);
          });
      }, []);
    
  return (
    <div>
    <HeaderTemp />    
    <NavBarTest />
    {/* <h1>Projects Page</h1> */}
    <section class="section-padding" id="section_3">
      <div class="container">
          <div class="row">

              <div class="col-lg-12 col-12 text-center mb-4">
                  <h2>Our Events</h2>
              </div>

                        {events.map(event => (
        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" key={event.eventId}>
          <div className="custom-block-wrap" style={{marginBottom:"50px"}}>
            <img style={{height:"200px"}} src={event.coverPhotoReference} className="custom-block-image img-fluid" alt="" />

            <div className="custom-block">
              <div className="custom-block-body" style={{height:"300px"}}>
                <h5 className="mb-3">{event.eventName}</h5>


                <p>{event.eventDescription}</p>

                <div className="event-info">
                  <div className="event-date-time">
                    <FontAwesomeIcon icon={faCalendarAlt} /> <strong>Date:</strong> {formatDate(event.eventDate)}  <br/>
                    <FontAwesomeIcon icon={faClock} /> <strong>Time:</strong> {event.startTime}
                  </div>
                  <div className="event-location">
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Location:</strong> {event.location}
                  </div>
                </div>

              </div>

              <a onClick={handleClick} className="custom-btn btn">Donate now</a>
            </div>
          </div>
        </div>
      ))}
                   </div>
                </div>
            </section>
            {showCardMessage && (
        <center>
          <MDBCard alignment='center' style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            {/* <MDBCardHeader>Thank you for the donation!</MDBCardHeader> */}
            <MDBCardBody>
              <MDBCardTitle>Thank you for your interest in donating!</MDBCardTitle>
              <MDBCardText>Thank you for your interest in donating to our causes. Currently, we are unable to process payments through our website due to a system update. Kindly make your donation to our official bank account. The bank account details are as follows,</MDBCardText>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MDBCardText style={{ textAlign: 'left' }}>
                  <strong>Account Name:</strong> EEGAI<br />
                  <strong>Account No:</strong> 0088131850<br />
                  <strong>Bank Name:</strong> Bank of Ceylon<br />
                  <strong>Branch Code:</strong> 414<br />
                  <strong>Swift Code:</strong> BCEYLKLX
                </MDBCardText>
              </div>
              <div className="button-container">
                  {pdfContent && (
                    <PDFDownloadLink document={pdfContent} fileName="donation_receipt.pdf">
                      {({ blob, url, loading, error }) =>
                        loading ? 'Generating PDF...' : 'Download Receipt'
                      }
                    </PDFDownloadLink>
                  )} 
                </div>
            </MDBCardBody>
            <MDBCardFooter>  
              <MDBBtn onClick={handleCloseCardMessage} className="hover-effect-button" style={{ padding: '5px 10px', fontSize: '12px' }}>
                Close
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </center>
      )}

    <Footer />
  </div>
  );
};

export default Events;
