// import React from 'react';
import React, { useState, useEffect  } from 'react';

// import Home from './Home';
import Home from './Home/Home.js';
import About from './NavBarItems/About.js';
import Contact from './Home/Contact';
import Projects from './NavBarItems/Projects.js';
import Activities from './NavBarItems/Activities.js';
import Events from './NavBarItems/Events.js';
import iHub from './Home/iHub.js';
import InnovationHub from './NavBarItems/InnovationHub.js';
import Crowdfunding from './Crowdfunding';
import GetInvolved from './GetInvolved';
import VolunteerRegistrationForm from './SignUp/VolunteerRegistrationForm.js';
import VisitingAppointment from './SignUp/VisitingAppointment.js';
// Import the withAuthorization HOC
//import withAuthorization from './WithAuthorization';
import Login from './Login';
import Signup from './SignUp/Signup.js';
import DonorRegistrationForm from './SignUp/DonorRegistrationForm.js';
import StaffRegistrationForm from './SignUp/StaffRegistrationForm.js';
import DonateUs from './DonateUs';
import Payment from './Admin/AdminManageProduct';
import AdminManageProduct from './Admin/AdminManageProduct';
import AdminProduct from './Admin/AdminProduct';
import AdminLogin from './Admin/AdminLogin';
import AdminEdit from './Admin/AdminEdit';
import Admin from './Admin/Admin';
import ManageGallery from './Admin/ManageGallery.js'
import ManageUsers from './Admin/ManageUsers';
import RequestVisitingAppointment from './NavBarItems/RequestVisitingAppointment.js';
import ManageVistingAppointments from './Admin/ManageVistingAppointments';
import ManageProjects from './Admin/ManageProjects';
import CreateProject from './Admin/CreateProject';
import ContactPage from './NavBarItems/ContactPage.js';
import ProjectForm from './Admin/ProjectForm';
import ProjectTest from './Admin/ProjectTest';
import DonatePage from './DonatePage';
import CreateEvent from './Admin/CreateEvent';
import ProjectStaffReport from './Admin/ProjectStaffReport';
import ProjectDonationReport from './Admin/ProjectDonationReport';
import CreateActivity from './Admin/CreateActivity';
import DonorDashboard from './DonorDashboard/DonorDashboard';
import VolunteerAssignment from './Admin/VolunteerAssignment';
import ManageActivity from './Admin/ManageActivity';
import ActivityStaffReport from './Admin/ActivityStaffReport';
import ActivityDonationReport from './Admin/ActivityDonationReport';
import ManageProject from './StaffDashboard/ManageProject';
import StaffManageActivity from './StaffDashboard/StaffManageActivity';
import StaffManageEvent from './StaffDashboard/StaffManageEvent';
import Assignment from './Admin/Assignment';
import DonatePageforDonor from './Donation/DonatePageforDonor.js';
import DonationNav from './DonationNav';
import ProjectVolunteerReport from './Admin/ProjectVolunteerReport';
import ActivityVolunteerReport from './Admin/ActivityVolunteerReport';
import ManageEvent from './Admin/ManageEvent';
import EventStaffReport from './Admin/EventStaffReport';
import EventVolunteerReport from './Admin/EventVolunteerReport';
import EditProjectPage from './Admin/EditProjectPage';
import Donate from './Donate';
import CheckoutTest from './Donation/CheckoutTest.js';
import DonateUsTest from './DonateUsTest';
import DonationDashboard from './Admin/DonationDashboard';
import VolunteerAssignmentDashboard from './Admin/VolunteerAssignmentDashboard';
import StaffAssignmentDashboard from './Admin/StaffAssignmentDashboard';
import DonateOnUSD from './Donation/DonateOnUSD.js';
import Tree from './Admin/Tree';
import ManageDonation from './Admin/ManageDonation';
import SignIn from './SignUp/SignIn.js';
import Carousel from './Carousel/Carousel.tsx';
import SignUpNew from './SignUp/SignupNew.js';
import VolunteerSignup from './SignUp/VolunteerSignup.js';
import VolunteerTest from './SignUp/VolunteerTest.js';
import DonorSignup from './SignUp/DonorSignup.js';
import Register from './SignUp/Register.js';
import OurDirectors from './Home/OurDirectors.js';
import OurTeam from './Home/OurTeam.js';
import ExecutiveCommittee from './Home/ExecutiveCommittee.js';
import SeniorManagementTeam from './Home/SeniorManagementTeam.js';
import Gallery from './NavBarItems/Gallery.js';
import Mentors from './Home/Mentors.js';
import OurImpactVentures from './Home/OurImpactVentures.js';

// imports for Volunteer Dashboard 
import VolunteerDashboard from './VolunteerDashboard/VolunteerDashboard';
import StaffDashboard from './StaffDashboard/StaffDashboard';
import ManageDonationHistory from './DonorDashboard/ManageDonationHistory';
import VolunteerManageProjects from './VolunteerDashboard/VolunteerManageProjects';
import VolunteerManageActivity from './VolunteerDashboard/VolunteerManageActivity';
import VolunteerManageEvent from './VolunteerDashboard/VolunteerManageEvent';



// import Menu from './Admin/Menu';
// import CheckoutForm from './CheckoutForm';


// import Contacts from "./scenes/Contacts";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';



 // Use the withAuthorization HOC to wrap the protected component
 //const AuthorizedDashboard = withAuthorization(['admin'])(Dashboard);

const MainRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Retrieve the value of 'loggedIn' from local storage
    const isLoggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    if (isLoggedIn) {
      setLoggedIn(isLoggedIn);
    }
  }, []);

  return (
    <HashRouter>
      <Routes> 
        <Route path="/" element={<Home/>} /> 
        <Route path="/about" element={<About/>} /> 
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/Projects" element={<Projects />} /> 
        <Route path="/Activities" element={<Activities />} /> 
        <Route path="/Events" element={<Events />} /> 
        <Route path="/GetInvolved" element={<GetInvolved />} /> 
        <Route path="/Crowdfunding" element={<Crowdfunding />} /> 
        <Route path="/VolunteerRegistrationForm" element={<VolunteerRegistrationForm />} /> 
        <Route path="/DonateUs" element={<DonateUs />} /> 
        <Route path="/Login" element={<Login />} /> 
        <Route path="/Signup" element={<Signup />} /> 
        <Route path="/DonorRegistrationForm" element={<DonorRegistrationForm />} /> 
        <Route path="/StaffRegistrationForm" element={<StaffRegistrationForm />} />
        <Route path="/RequestVisitingAppointment" element={<RequestVisitingAppointment />} />
        <Route path="/ContactPage" element={<ContactPage />} />
        <Route path="/DonatePage" element={<DonatePage />} />
        <Route path="/VolunteerAssignment" element={<VolunteerAssignment />} />
        <Route path="/Assignment" element={<Assignment />} />
        <Route path="/DonatePageforDonor" element={<DonatePageforDonor/>} />
        <Route path="/DonationNav" element={<DonationNav/>} />
        <Route path="/Donate" element={<Donate/>} />
        <Route path="/CheckoutTest" element={<CheckoutTest/>} />
        <Route path="/DonateUsTest" element={<DonateUsTest/>} />
        <Route path="/DonateOnUSD" element={<DonateOnUSD/>} />
        <Route path="/SignIn" element={<SignIn/>} />
        <Route path="/Carousel" element={<Carousel/>} />
        <Route path="/SignupNew" element={<SignUpNew/>} />
        <Route path="/VolunteerSignup" element={<VolunteerSignup/>} />
        <Route path="/VolunteerTest" element={<VolunteerTest/>} />
        <Route path="/DonorSignup" element={<DonorSignup/>} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/VisitingAppointment" element={<VisitingAppointment/>} />
        <Route path="/Register" element={<Register/>} />
        <Route path="/OurDirectors" element={<OurDirectors/>} />
        <Route path="/OurTeam" element={<OurTeam/>} />
        <Route path="/ExecutiveCommittee" element={<ExecutiveCommittee/>} />
        <Route path="/SeniorManagementTeam" element={<SeniorManagementTeam/>} />
        <Route path="/Mentors" element={<Mentors/>} />
        <Route path="/iHub" element={<iHub/>} />
        <Route path="/InnovationHub" element={<InnovationHub/>} />
        <Route path="/OurImpactVentures" element={<OurImpactVentures/>} />
        {/* <Route path="/CheckoutForm" element={<CheckoutForm />} /> */}

        {/* Admin Page Routes */}
        <Route path="/payment" element={<Payment />} />
        <Route path="/products" element={<AdminProduct />}></Route>
        <Route path="/manageProduct" element={<AdminManageProduct />}></Route>
        <Route path="/editProduct/:id" element={<AdminEdit />}></Route>
        <Route path="/users" element={<ManageUsers />}></Route>
        <Route path="/adminLogin" element={<AdminLogin />}></Route>
        <Route path="/ManageVistingAppointments" element={<ManageVistingAppointments />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/ManageProjects" element={<ManageProjects />}></Route>
        <Route path="/CreateProject" element={<CreateProject />}></Route>
        <Route path="/CreateActivity" element={<CreateActivity />}></Route>
        <Route path="/CreateEvent" element={<CreateEvent />}></Route>
        <Route path="/ManageUsers" element={<ManageUsers />}></Route>
        <Route path="/ProjectStaffReport" element={<ProjectStaffReport />}></Route>
        <Route path="/ProjectDonationReport" element={<ProjectDonationReport />}></Route>
        <Route path="/ManageActivity" element={<ManageActivity />}></Route>
        <Route path="/ActivityStaffReport" element={<ActivityStaffReport />}></Route>
        <Route path="/ActivityDonationReport" element={<ActivityDonationReport />}></Route>
        <Route path="/ProjectVolunteerReport" element={<ProjectVolunteerReport />}></Route>
        <Route path="/ActivityVolunteerReport" element={<ActivityVolunteerReport />}></Route>
        <Route path="/ManageEvent" element={<ManageEvent />}></Route>
        <Route path="/EventStaffReport" element={<EventStaffReport />}></Route>
        <Route path="/EventVolunteerReport" element={<EventVolunteerReport />}></Route>
        <Route path="/EditProjectPage" element={<EditProjectPage />}></Route>
        <Route path="/DonationDashboard" element={<DonationDashboard/>} />
        <Route path="/VolunteerAssignmentDashboard" element={<VolunteerAssignmentDashboard/>} />
        <Route path="/StaffAssignmentDashboard" element={<StaffAssignmentDashboard/>} />
        <Route path="/Tree" element={<Tree/>} />
        <Route path="/ManageDonation" element={<ManageDonation/>} />
        <Route path="/ProjectForm" element={<ProjectForm />}></Route>
        <Route path="/ProjectTest" element={<ProjectTest />}></Route>
        <Route path="/ManageGallery" element={<ManageGallery/>} />
        {/* End of Admin Page Routes */}

        {/* Volunteer Dashboard */}
        <Route path="/VolunteerDashboard" element={<VolunteerDashboard />}></Route>
        <Route path="/VolunteerManageProjects" element={<VolunteerManageProjects />}></Route>
        <Route path="/VolunteerManageActivity" element={<VolunteerManageActivity />}></Route>
        <Route path="/VolunteerManageEvent" element={<VolunteerManageEvent />}></Route>

        {/* End of Volunteer Dashboard */}

        {/* Donor Dashboard */}
        <Route path="/DonorDashboard" element={<DonorDashboard />}></Route>
        <Route path="/ManageDonationHistory" element={<ManageDonationHistory />}></Route>

        {/* End of Donor Dashboard */}

         {/* Staff Dashboard */}
         <Route path="/StaffDashboard" element={<StaffDashboard />}></Route>
         <Route path="/ManageProject" element={<ManageProject/>}></Route>
         <Route path="/StaffManageActivity" element={<StaffManageActivity/>}></Route>
         <Route path="/StaffManageEvent" element={<StaffManageEvent/>}></Route>

        {/* End of Staff Dashboard */}

         {/* Use the AuthorizedDashboard in your route definition or component rendering 
         <Route path="/Dashboard" component={AuthorizedDashboard} />*/}
      </Routes> 
      {/* <Footer />  */}
      {/* Add the Footer component here */}
      </HashRouter>
  
    
  );
};

export default MainRoutes;
