import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";

const partners = [
  {
    href: "https://www.ecpat.lk/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549057/partner1_rav5cr.jpg",
    alt: "ECPAT",
    height: '200px',
    width: '200px'
  },
  {
    href: "https://dreamspace.academy/pages/1-0-index.php",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549063/partner2_noecam.jpg",
    alt: "DreamSpace Academy",
    height: '200px',
    width: '200px'
  },
  {
    href: "https://www.sarvodaya.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549060/partner3_i1357w.jpg",
    alt: "Sarvodaya",
    height: '200px',
    width: '200px'
  },
  {
    href: "https://www.uri.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549073/partner4_z1uqzu.jpg",
    alt: "URI",
    height: '200px',
    width: '200px'
  },
  {
    href: "https://www.kirkonulkomaanapu.fi/en/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549066/partner5_w5n2uw.jpg",
    alt: "Finn Church Aid",
    height: '200px',
    width: '200px'
  },
  {
    href: "https://www.sarvodaya.org/shanthi-sena",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549064/partner6_dfqaux.png",
    alt: "Sarvodaya Shanthi Sena",
    height: '160px',
    width: '160px'
  },
  {
    href: "https://www.peacemakersnetwork.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549072/partner7_hqfbcg.jpg",
    alt: "Global Peace Network",
    height: '120px',
    width: '160px'
  },
  {
    href: "https://www.koddi.or.kr/eng/greeting.jhtml",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549070/partner8_qmvdht.png",
    alt: "KODDI",
    height: '140px',
    width: '140px'
  },
  {
    href: "https://eegaisrilanka.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717921757/WhatsApp_Image_2024-06-06_at_13.37.54_dy5kuq.jpg",
    alt: "Eegai Sri Lanka",
    height: '160px',
    width: '160px'
  },
  {
    href: "https://eegaisrilanka.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717921761/WhatsApp_Image_2024-06-06_at_13.39.47_vff4mg.jpg",
    alt: "Jans property care",
    height: '160px',
    width: '160px'
  },
  {
    href: "https://eegaisrilanka.org/",
    imgSrc: "https://res.cloudinary.com/dhzvwxfod/image/upload/v1717922221/Screenshot_2024-06-09_at_14.06.54_rift8g.png",
    alt: "Help Feed One",
    height: '160px',
    width: '200px'
  },
];

const Partners = () => {
  return (
    <div>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 text-center mx-auto">
              <h2 className="mb-5">Our Partners</h2>
            </div>
            {partners.map((partner, index) => (
              <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0" key={index}>
                <div className="featured-block d-flex justify-content-center align-items-center">
                  <a href={partner.href} className="d-block">
                    <img
                      src={partner.imgSrc}
                      className="featured-block-image img-fluid"
                      alt={partner.alt}
                      style={{ height: partner.height, width: partner.width }}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
