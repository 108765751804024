import React from "react";
import VolunteerSideBar from "./VolunteerSideBar";

function VolunteerDashboard() {
  return (
    <div>
      <VolunteerSideBar />
    </div>
  );
}
export default VolunteerDashboard;