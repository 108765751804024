import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import '../TeamMembers.css';

const ourDirectors = [
    {
        name: 'Karmegam Nishanthan',
        title: 'Co-Founder & CEO',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549594/Director_2_spgv2y.jpg',
        description1: 'Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito Professional charity theme based',
        description2: 'You are not allowed to redistribute this template ZIP file on any other template collection website. Please contact TemplateMo for more information.',
        linkedin: 'https://www.linkedin.com/in/karmegam-nishanthan-86032456'
    },
    {
        name: 'Jeevaraj Sapthiha',
        title: 'Co-Founder & Director',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1717488878/Director-02_y7sifp.jpg',
        description1: 'Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito Professional charity theme based',
        description2: 'You are not allowed to redistribute this template ZIP file on any other template collection website. Please contact TemplateMo for more information.',
        linkedin: 'https://www.linkedin.com/in/sapthihajeevaraj'
    },
    {
        name: 'Velmurugu Jeevaraj',
        title: 'Director',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549597/Director_4_j6vafo.jpg',
        description1: 'Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito Professional charity theme based',
        description2: 'You are not allowed to redistribute this template ZIP file on any other template collection website. Please contact TemplateMo for more information.',
        linkedin: 'https://www.linkedin.com/in/jeevaraj-velmurugu-b62b11292'
    },
    {
        name: 'Ulaganathan Thusyanthan',
        title: 'Director',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549597/Director_4_1_ee2hct.jpg',
        description1: 'Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito Professional charity theme based',
        description2: 'You are not allowed to redistribute this template ZIP file on any other template collection website. Please contact TemplateMo for more information.',
        linkedin: '#'
    },
    {
        name: 'Najeemudeen Mohamed Sasly',
        title: 'Director',
        image: 'https://res.cloudinary.com/dhzvwxfod/image/upload/v1699549599/director_5_zf5o9b.jpg',
        description1: 'Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito Professional charity theme based',
        description2: 'You are not allowed to redistribute this template ZIP file on any other template collection website. Please contact TemplateMo for more information.',
        linkedin: '#'
    },

];

const OurDirectors = () => {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                        <h2>Meet Our Directors</h2>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                        {ourDirectors.map((director, index) => (
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 p-1 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                                <div className="team-item position-relative rounded overflow-hidden">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src={director.image} alt={director.name} style={{ minHeight: 300 }} />
                                    </div>
                                    <div className="team-text bg-light text-center p-3">
                                        <h5>{director.name}</h5>
                                        <p className="text-primary mb-1">{director.title}</p>
                                        <div className="team-social text-center">
                                            <a className="btn btn-square" href={director.linkedin}><i className="fab fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurDirectors;
