import React from 'react';
import "../css/bootstrap.min.css";
import "../css/bootstrap-icons.css";
import "../css/templatemo-kind-heart-charity.css";
import '../TeamMembers.css';
import ExecutiveCommittee from './ExecutiveCommittee';
import SeniorManagementTeam from './SeniorManagementTeam';
import TeamMembers from './TeamMembers';
import Mentors from './Mentors';

const OurTeam = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <h2>Our Team</h2>
        </div>
        <ExecutiveCommittee/>
        <SeniorManagementTeam/>
        <TeamMembers/>
        <Mentors/>


      </div>
    </div>
  );
};

export default OurTeam;
