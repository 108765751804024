import React, { useState, useEffect }  from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import slide1 from '../images/slide/medium-shot-people-collecting-donations.jpg';
import slide2 from '../images/slide/volunteer-helping-with-donation-box.jpg';
import slide3 from '../images/different-people-doing-volunteer-work.jpg';
import "../css/Carousal.css";


const Carousal = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 16);
    }, 2000); // Adjust the slide interval as needed (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <Carousel selectedItem={currentSlide}>
    
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865771/1_vcgq7o.png" alt="Slide 1"/>
          {/* <p className="legend">Legend 1</p> */}
          {/* <div className="legend">
            <h1>Educate A Child</h1>
            <p>Some description about Slide 1</p>
            <button>Learn More</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865769/2_qtxj02.png" alt="Slide 2"  />
          {/* <p className="legend">Legend 2</p> */}
          {/* <div className="legend">
            <h1>Help the needy</h1>
            <p>Some description about Slide 1</p>
            <button>Learn More</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865770/3_c5yitf.png"  alt="Slide 3" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Good health and well-being</h1>
            <p>Some description about Slide 1</p>
            <button>Learn More</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865769/4_ij9lej.png"  alt="Slide 4" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Respect for senior citizens</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865767/5_p0w3ms.png"  alt="Slide 5" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Peace and social cohesion</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865768/6_h4ozn3.png"  alt="Slide 6" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>creating awareness</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865766/7_rt1sel.png"  alt="Slide 7" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Habitat for humanity</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865766/8_md85co.png"  alt="Slide 8" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865766/9_bch0jm.png"  alt="Slide 9" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865770/10_ghz5io.png"  alt="Slide 10" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865767/11_sj3sih.png"  alt="Slide 11" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865768/12_eqwpdc.png"  alt="Slide 12" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865770/13_dhv8z4.png"  alt="Slide 13" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865771/14_butgsn.png"  alt="Slide 14" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865769/15_m6fgzu.png"  alt="Slide 15" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865772/16_ql1pxl.png"  alt="Slide 16" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865767/17_szedeo.png"  alt="Slide 17" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865766/18_fchu54.png"  alt="Slide 18" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865766/19_zzqmsr.png"  alt="Slide 19" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865768/20_wmdf2c.png"  alt="Slide 20" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>
      <div>
          <img src="https://res.cloudinary.com/dhzvwxfod/image/upload/v1717865765/21_fqsonj.png"  alt="Slide 21" />
          {/* <p className="legend">Legend 3</p> */}
          {/* <div className="legend">
            <h1>Slide 1</h1>
            <p>Some description about Slide 1</p>
            <button>Button 1</button>
          </div> */}
      </div>

    
    </Carousel>

  );
};

export default Carousal;



