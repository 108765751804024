import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderTemp from '../HeaderTemp';
import Footer from '../Footer';
import NavBarTest from '../NavBarTest';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [filter, setFilter] = useState('all');
  
    useEffect(() => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/api/gallery')
        .then(response => response.json())
        .then(data => {
          console.log('Fetched Images:', data);
          setImages(data);
        })
        .catch(error => console.error('Error fetching images:', error));
    }, []);
  
    const handleFilterChange = (newFilter) => {
      console.log('Filter Changed:', newFilter);
      setFilter(newFilter);
    };
  
    const filteredItemData = images.filter(item => {
      console.log('Filtering Item:', item);
      console.log('Current Filter:', filter);
      return filter === 'all' || item.sdg === filter;
    });
  
    console.log('Filtered Item Data:', filteredItemData);
  
  

  const filterButtons = [
    { label: 'All', filter: 'all' },
    { label: 'No Poverty', filter: 'No Poverty' },
    { label: 'Zero Hunger', filter: 'Zero Hunger' },
    { label: 'Good Health and Well-being', filter: 'Good Health and Well-being' },
    { label: 'Quality Education', filter: 'Quality Education' },
    { label: 'Clean Water and Sanitation', filter: 'Clean Water and Sanitation' },
    { label: 'Affordable and Clean Energy', filter: 'Affordable and Clean Energy' },
    { label: 'Decent Work and Economic Growth', filter: 'Decent Work and Economic Growth' },
    { label: 'Industry, Innovation and Infrastructure', filter: 'Industry, Innovation and Infrastructure' },
    { label: 'Reduced Inequality', filter: 'Reduced Inequality' },
    { label: 'Sustainable Cities and Communities', filter: 'Sustainable Cities and Communities' },
    { label: 'Responsible Consumption and Production', filter: 'Responsible Consumption and Production' },
    { label: 'Climate Action', filter: 'Climate Action' },
    { label: 'Life Below Water', filter: 'Life Below Water' },
    { label: 'Life on Land', filter: 'Life on Land' },
    { label: 'Peace, Justice and Strong Institutions', filter: 'Peace, Justice and Strong Institutions' },
    { label: 'Global Partnership for SDG', filter: 'Global Partnership for SDG' },

  ];

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getCols = () => {
    if (matchesLg) return 4;
    if (matchesMd) return 3;
    if (matchesSm) return 2;
    if (matchesXs) return 1;
  };
  console.log("Filtered Item Data:", filteredItemData);

  return (
    <div>
    <HeaderTemp />    
    <NavBarTest />
      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        <Grid container spacing={1} justifyContent="center">
          {filterButtons.map((btn) => (
            <Grid item key={btn.filter}>
              <Button
                variant="contained"
                onClick={() => handleFilterChange(btn.filter)}
                sx={{
                  padding: '2px 8px',
                  fontSize: '12px',
                  borderRadius: '15px',
                  minWidth: 'auto'
                }}
              >
                {btn.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ width: '100%', padding: '16px', marginTop: 2 }}>
      <ImageList variant="masonry" cols={getCols()} gap={8}>
  {filteredItemData.map((item) => {
    return (
      <React.Fragment key={item.sdg_id}>
        {item.image_references.split(',').map((imageUrl, index) => (
          <ImageListItem key={`${item.sdg_id}-${index}`}>
            <img
              src={imageUrl.trim()} // Trim any whitespace from the URL
              alt={item.sdg}
              loading="lazy"
              style={{ width: '100%' }}
            />
          </ImageListItem>
        ))}
      </React.Fragment>
    );
  })}
</ImageList>

</Box>

      <Footer />
    </div>
  );
};

export default Gallery;