import React from 'react';
import { Link } from 'react-router-dom';
import "./css/bootstrap.min.css";
import "./css/bootstrap-icons.css";
import "./css/templatemo-kind-heart-charity.css";
import HeaderTemp from './HeaderTemp';
import NavBarTest from './NavBarTest';
import Footer from './Footer';

const DonationNav = () => {
  return (
    
    <div>
    <HeaderTemp />
      <NavBarTest />
        <section class="donate-section">
                <div class="section-overlay"></div>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mx-auto">
                            <form class="custom-form donate-form" action="#" method="get" role="form">
                                <center>
                                <h3 class="mb-4">Join EEGAI today and start your journey with us</h3>

                                <div class="row">

                                    <Link to="/VolunteerRegistrationForm">
                                        <button type="submit" class="form-control mt-4" style={{ width: '200px' }}>Register As Volunteer</button>
                                    </Link >

                                    <Link to="/DonorRegistrationForm">
                                        <button type="submit" class="form-control mt-4" style={{ width: '200px' }}>Register As Donor</button>
                                    </Link >

                                </div>
                                </center>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        <Footer />
  </div>
  );
};

export default DonationNav;
